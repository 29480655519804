import React from "react";
import {
  Box,
  Typography,
  Modal,
  Divider,
  Button,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 440,
  boxShadow: 24,
  borderRadius: "4px",
  bgcolor: "background.paper",
};

const useStyles = makeStyles((theme) => ({
  headingSection: {
    display: "flex",
    padding: "20px 20px 0px",
    justifyContent: "space-between",
  },
  headingIcon: {
    height: "36px",
    width: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "12px",
    // background: theme.palette.green.light,
    borderRadius: "50%",
    zIndex: -1,
  },
  headingTitle: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
  },
  headingWrapper: {
    display: "flex",
  },
  headingCloseIcon: {
    cursor: "pointer",
  },
  modalContent: {
    margin: "28px 20px 28px",
    maxHeight: "calc(100vh - 200px)",
    // overflowY: "auto",
  },
  modalButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    padding: "12px 20px 12px",
  },
  modalSubmit: {
    color: theme.palette.background.default,
  },
  backdrop: {
    backdropFilter: "blur(2px)",
    backgroundColor: "rgba(49, 62, 79, 0.44)",
  },
}));

const CommonModal = ({
  open,
  handleClose,
  title,
  onSubmit,
  icon,
  cancelButtonName,
  sendButtonName,
  children,
  isDeleting,
  width,
  height,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const headingIconBgColor =
    sendButtonName === "Ping" || isDeleting
      ? "#fff0e7"
      : theme.palette.green.light;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <Box sx={style} width={width ?? width}>
        <Box className={classes.headingSection}>
          <Box className={classes.headingWrapper}>
            <Box
              className={classes.headingIcon}
              style={{ background: headingIconBgColor }}
            >
              <Box
                width={"20px"}
                height={"20px"}
                component={"img"}
                src={icon ?? "/assets/icons/briefcase.png"}
              />
            </Box>
            <Typography
              className={classes.headingTitle}
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ fontWeight: "normal" }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            className={classes.headingCloseIcon}
            component={"img"}
            onClick={handleClose}
            src="/assets/icons/x.svg"
          />
        </Box>
        <Box className={classes.modalContent} height={height ?? height}>
          {children}
        </Box>
        <Divider />
        <Box>
          <Box className={classes.modalButton}>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="orangeButton"
              sx={{ height: "35px" }}
            >
              {cancelButtonName ?? "Cancel"}
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();
                onSubmit();
              }}
              variant="contained"
              className={classes.modalSubmit}
              color="greenButton"
              sx={{ color: "white", height: "35px" }}
            >
              {sendButtonName ?? "Add"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonModal;

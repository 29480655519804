import { useSnackbar } from "notistack";
export function useMessage() {
  const { enqueueSnackbar } = useSnackbar();

  const message = (
    message = "Hi there! I'm a snackbar message",
    variant = "default",
    options = {
      autoHideDuration: 1000,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    }
  ) => {
    enqueueSnackbar(message, {
      variant,
      ...options,
    });
    console.log("Snackbar message displayed");
  };

  return message;
}

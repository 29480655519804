import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import Iconify from "../iconify/Iconify";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "296px",
    },
    width: "100%",
  },
}));

const SearchTextField = ({ placeholder = "Search", onChange }) => {
  const classes = useStyles();

  return (
    <TextField
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{ marginRight: "4px" }} position="start">
            <Iconify
              icon="material-symbols-light:search"
              width={"20px"}
              color={"primary.black"}
            />
          </InputAdornment>
        ),
      }}
      className={classes.searchInput}
      sx={{
        "& input": {
          padding: "9px 12px",
          fontSize: "14px",
        },
      }}
      // size="small"
      onChange={onChange}
    />
  );
};

SearchTextField.propTypes = {
  placeholder: String,
  onChange: Function,
};

export default SearchTextField;

import { Box, Typography } from '@mui/material'
import React from 'react'
import QuestionAnalyticsDataTable from '../assesmentPage/QuestionAnalytics/QuestionAnalyticsDataTable'

const QuestionsAnalytics = () => {

    const data = [
        {
            icon: "/assets/questionanalytics1.svg",
            label: "100",
            subTitle: "Total Question"
        },
        {
            icon: "/assets/questionanalytics2.svg",
            label: "80",
            subTitle: "Attempted"
        }, {
            icon: "/assets/questionanalytics3.svg",
            label: "05",
            subTitle: "Unattempted"
        }
    ]

    return (
        <Box>
            <Box sx={{ display: "flex", gap: "12px", marginTop: "16px", flexDirection: { sm: "row", base: "column" } }}>
                {data.map((elem, i) => (
                    <Box key={i} sx={{ width: { sm: "33.33%", base: "100%" }, border: "1px solid #E8E9EE", height: "84px", borderRadius: "4px", display: "flex", alignItems: "center", padding: "0 12px", gap: "12px" }}>
                        <Box><img src={elem.icon} alt="" /></Box>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Typography sx={{
                                fontFamily: 'Nunito',
                                fontWeight: "700",
                                fontSize: '20px',
                                lineHeight: '32px',
                                color: "#363E45"
                            }}>{elem.label}</Typography>
                            <Typography sx={{
                                fontFamily: 'Roboto',
                                fontWeight: "400",
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: "#636A75"
                            }}>{elem.subTitle}</Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box>
                <QuestionAnalyticsDataTable />
            </Box>
        </Box>
    )
}

export default QuestionsAnalytics
import { Box, Divider, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { HiOutlineBriefcase } from "react-icons/hi";
import { LuUsers } from "react-icons/lu";
import { styled } from "@mui/system";
const useStyles = makeStyles((theme) => ({
  candidateName: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    fontFamily: "Roboto",
    color: "#313131",
  },
  candidateEmail: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    fontFamily: "Roboto",
    color: "#535A5F",
    height: "16px",
  },
}));
const CandidateName = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "20px",
  fontFamily: "Roboto",
  color: "#313131",
}));

const CandidateEmail = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14px",
  fontFamily: "Roboto",
  color: "#535A5F",
  height: "16px",
}));

const getRoundStyles = (round) => {
  switch (round) {
    case "R1 Scheduled":
    case "R2 Scheduled":
    case "HR Scheduled":
      return {
        backgroundColor: "#FFF0E7",
        color: "#FF6812",
      };
    case "R2 Passed":
    case "R1 Passed":
    case "HR Passed":
      return {
        backgroundColor: "#E2F4C5",
        color: "#285837",
      };
    case "Hired":
      return {
        backgroundColor: "#D5F9F1",
        color: "#004E3E",
      };
    case "R1 Failed":
    case "R2 Failed":
    case "HR Failed":
      return {
        backgroundColor: "#FADCDA",
        color: "#733430",
      };
    case "R1 Pending":
    case "R2 Pending":
    case "HR Pending":
      return {
        backgroundColor: "#FAFFAF",
        color: "#9E6F21",
      };
    case "Shortlisted":
      return {
        backgroundColor: "#EEF7FF",
        color: "#003285",
      };
    default:
      return {};
  }
};
const ShortlistedCandidateCard = ({ data }) => {
  const roundStyles = getRoundStyles(data.round);
  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      alignItems={"center"}
      sx={{
        padding: "16px",
        backgroundColor: "#FFFFFF",
        gap: "12px",
        border: "1px solid #E8E9EE",
        borderRadius: "4px",
        minHeight: "100px",
      }}
    >
      {/* left section */}
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        gap={"12px"}
        flexGrow={1}
        sx={{ padding: 0, gap: "12px" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"12px"}
        >
          <Box
            component={"img"}
            src={data.avtar}
            sx={{ borderRadius: "50%" }}
            width={"40px"}
            height={"40px"}
          />
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            gap={"4px"}
            sx={{ gap: "4px" }}
          >
            <Link
              to="/talent_pool/cadidateDetails"
              style={{ textDecoration: "none" }}
            >
              <CandidateName>{data.candidateName}</CandidateName>
            </Link>
            <CandidateEmail> {data.email}</CandidateEmail>
          </Stack>
        </Stack>

        <Stack direction={"row"} alignItems={"flex-start"} gap={"12px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"8px"}
            sx={{ textWrap: "nowrap" }}
          >
            <Box
              //   component={"img"}
              //   src="./assets/icons/briefcase.svg"
              width={"16px"}
              height={"16px"}
              color={"#69778A"}
              sx={{ color: "#69778A" }}
            >
              <HiOutlineBriefcase size={16} color="#69778A" />
            </Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: " 12px",
                lineHeight: "16px",
                color: "#535A5F",
              }}
            >
              {data.role}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"8px"}
            sx={{ textWrap: "nowrap" }}
          >
            <Box
              //   component={"img"}
              //   src="./assets/icons/Users.svg"
              width={"16px"}
              height={"16px"}
            >
              <LuUsers size={16} color="#69778A" />
            </Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#535A5F",
              }}
            >
              {data.personName}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* right section */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"12px"}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          gap={"2px"}
          sx={{ padding: "0" }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontWeight: 700,
              fontSize: "24px",
              lineHeight: "32px",
              color: "#333333",
            }}
          >
            {data.testScore}%
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              color: "#535A5F",
              textWrap: "nowrap",
            }}
          >
            Test Score
          </Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          gap={"2px"}
          justifyContent={"center"}
          sx={{
            padding: "4px 12px",
            gap: "4px",
            backgroundColor: roundStyles.backgroundColor,
            borderRadius: "20px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: roundStyles.color,
              textWrap: "nowrap",
            }}
          >
            {data.round}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ShortlistedCandidateCard;

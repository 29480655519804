import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const DrawerHeader = ({ headingText, setToggleDrawer, handleClose }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 16px",
          borderBottom: "1px solid #E9EAEB",
          height: "56px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            padding: "12px 0px",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            fontFamily: "Nunito",
            color: theme.palette.primary.black,
          }}
          color="blackButton"
        >
          {headingText}
        </Typography>
        <Box
          component={"img"}
          color={theme.palette.gray.main}
          onClick={() => {
            handleClose && handleClose();
            setToggleDrawer((prev) => !prev);
          }}
          src="/assets/icons/x.svg"
          sx={{ cursor: "pointer" }}
        />
      </Box>
    </>
  );
};

export default DrawerHeader;

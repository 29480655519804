import React from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";

const TabsList = ({ tabs, handleTabChange,value }) => {
  // const [value, setValue] = React.useState(tabs[0].value);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    handleTabChange(newValue)
  };

  return (
    <Box
      sx={{
        background: "transparent",
        borderBottom: "1px solid #E8E9EE",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="#00C49A"
        aria-label="secondary tabs example"
        sx={{
          overflow: "auto",
          "& .MuiTabs-scroller": {
            overflowX: "auto !important",
            width: "100%",
          },
          "& .MuiTab-root": {
            lineHeight: "20px",
            fontSize: "14px",
            fontWeight: "400",
            color: "#71777B",
            borderBottom: "none",
            "&.Mui-selected": {
              color: "#00C49A",
              borderBottom: "1px solid #00C49A",
              fontWeight: "600",
            },
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            sx={{ textTransform: "capitalize" }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabsList;

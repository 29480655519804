import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTable from "../common/customTable/CustomTable";
import DifficultyIcon from "./DifficultyIcon";
import { question_table_two } from "../../utils/data";
const columnsWidth = ["60px", "244px", "244px", "320px", "244px"];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

const tableHeaderTitles = [
  {
    title: "Sr. no",
    cellStyleProps: {
      borderRight: "1px solid #E8E9EE",
      width: columnsWidth[0],
    },
  },
  {
    title: "Skill Name",
    cellStyleProps: {
      width: columnsWidth[1],
    },
  },
  {
    title: "Questions",
    cellStyleProps: {
      width: columnsWidth[2],
    },
  },
  {
    title: "Section",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Score",
    cellStyleProps: {
      width: columnsWidth[4],
    },
  },
];
const tableHeaderCells = () => {
  return tableHeaderTitles.map((item) => {
    return {
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};
const SkillsTable = () => {
  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.id}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.skill}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.questions}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {row.section.map((item) => {
              return (
                <Typography
                  key={item}
                  sx={{
                    display: "inline-block",
                    background: "#D5F9F1",
                    padding: "2px 8px",
                    textAlign: "center",
                    color: "#004E3E",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    fontFamily: "Roboto",
                    borderRadius: "0.25rem",
                    whiteSpace: "nowrap",
                    height: "20px",
                  }}
                >
                  {item}
                </Typography>
              );
            })}
          </Box>
        ),
      },
      {
        cellStyleProps: {
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.score}
          </Typography>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return question_table_two.map((row) => {
      return {
        rowElement: tableBodyRowCell(row, cellStyleProps),
      };
    });
  };
  const getDifficultyTag = (color, bg, text) => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: bg,
          padding: "2px 8px",
          color: color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
          height: "20px",
        }}
      >
        <DifficultyIcon color={color} />
        {text}
      </Box>
    );
  };
  return (
    <CustomTable
      tableHeaderCells={tableHeaderCells()}
      tableBodyContent={tableBodyContent()}
      tableData={question_table_two}
      sortRows={() => {}}
      tableBodyRowStyleProps={tableBodyRowStyleProps}
      tableHeaderCellStyleProps={tableHeaderCellStyleProps}
      isPaginationVisible={false}
    />
  );
};

export default SkillsTable;

import {
  Box,
  Button,
  Drawer,
  IconButton,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NAV } from "../config-global";
import useResponsive from "../hooks/useResponsive";
import Scrollbar from "../components/scrollbar";
import Iconify from "../components/iconify/Iconify";
import NavSectionVertical from "./NavSectionVertical";
import navConfig from "../config-nav";
import { navContext } from "../context/navContext";
import PropTypes from "prop-types";
import APICall from "../utils/api";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  marginTop: "18px",
  marginBottom: "12px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.main
        : "secondary.main",
  },
}));

function Logo() {
  return (
    <Box
      component="img"
      src="/assets/icons/Logo.svg"
      style={{
        width: "100px",
        height: "32px",
        marginLeft: "12px",
        marginBottom: "8px",
      }}
    />
  );
}

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  bottom: "0",
  left: "0",
  width: "100%",
  justifyContent: "flex-start",
  maxWidth: `${NAV.W_DASHBOARD}px`,
}));

export const Navbar = ({ open, setOpen, isVisible, onDismiss }) => {
  const { isNavMini, setIsNavMini } = useContext(navContext);
  const isDesktop = useResponsive("up", "md");
  const [invitesStats, setInvitesStats] = useState({});

  // const fetchInvitesStats = async () => {
  //   const response = await APICall("/stats/super-admin/invites/remaining");
  //   const data = response.data;
  //   setInvitesStats(data);
  //   console.log(data);
  //   return data;
  // };

  // useEffect(() => {
  //   fetchInvitesStats();
  // }, []);

  const renderContent = (
    <Scrollbar>
      <Box>
        <Box
          sx={{
            pt: "24px",
            pb: "16px",
            px: "12px",
            flexShrink: 1,
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "500px",
          }}
        >
          <Logo />
          <IconButton
            sx={{
              width: "24px",
              height: "24px",
              border: "1px solid",
              marginTop: "-10px",
              borderRadius: "4px",
              padding: 0,
              borderColor: "secondary.main",
            }}
            onClick={() => setIsNavMini(!isNavMini)}
          >
            <Iconify
              icon="solar:arrow-left-linear"
              width={"16px"}
              color={"secondary.main"}
            />
          </IconButton>
        </Box>
        <NavSectionVertical data={navConfig} />
      </Box>
      {isVisible && (
        <StyledRoot className="profileBox">
          <Box
            sx={{
              mx: "12px",
              mb: "16px",
              minWidth: 0,
              border: "1px solid",
              borderColor: "primary.gray",
              padding: "16px",
              borderRadius: "8px",
              width: "100%",
            }}
          >
            <Typography
              variant="subtitle2"
              noWrap
              sx={{
                fontSize: "16px",
                color: "primary.black",
                lineHeight: "20px",
                marginBottom: "4px",
              }}
            >
              You’re running low on invites!
            </Typography>
            <Typography
              variant="caption"
              wrap
              sx={{
                fontSize: "14px",
                color: "primary.black",
                lineHeight: "20px",
              }}
            >
              Your team has used 80% of your available invites. Need More??
            </Typography>
            <BorderLinearProgress variant="determinate" value={90} />
            <Box display={"flex"} gap={"16px"}>
              <Button
                sx={{
                  width: "74px",
                  height: "32px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  padding: "6px 16px",
                }}
                color="blackButton"
                variant="outlined"
                onClick={onDismiss}
              >
                Dismiss
              </Button>
              <Button
                sx={{
                  width: "74px",
                  height: "32px",
                  fontSize: "14px",
                  padding: "6px 16px",
                  lineHeight: "20px",
                }}
                size="small"
                variant="outlined"
                color="secondary"
              >
                Renew
              </Button>
            </Box>
          </Box>
        </StyledRoot>
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: { md: "230px" },
      }}
    >
      {/* <NavToggleButton /> */}

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 5,
              width: "230px",
              border: "none",
              bgColor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          onClick={() => setOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              border: "none",
              width: "230px",
              background: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

Navbar.propTypes = {
  open: PropTypes.string,
  setOpen: PropTypes.func,
};

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import Iconify from "../components/iconify/Iconify";
import { Navbar } from "./Navbar";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    width: "100%",
    height: "56px",
    top: "64px",
    position: "sticky",
    borderTop: "1px solid",
    borderColor: theme.palette.primary.gray,
    background: theme.palette.background.default,
    alignItems: "center",
    padding: "16px",
    zIndex:"1000"
  },
  mainLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
}));

function Logo() {
  return (
    <Box
      component="img"
      src="/assets/icons/Logo.svg"
      style={{
        width: "102px",
        height: "32px",
      }}
    />
  );
}

const NavHorizontal = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Box className={classes.main}>
      <Iconify
        width={24}
        color={"secondary.main"}
        icon="solar:hamburger-menu-linear"
        onClick={toggleDrawer(true)}
      />
      <Box className={classes.mainLogo}>
        <Logo />
      </Box>

      <Navbar open={open} setOpen={setOpen} />
    </Box>
  );
};

export default NavHorizontal;

import { Grid } from "@mui/material";
import ScheduleCard from "../dashboardPage/ScheduleCard";
import React from "react";
import HiredCandidateCard from "../common/HiredCandidateCard";
import { HiredScheduleData } from "../../utils/data";

const HiredGrid = () => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="stretch"
      sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
    >
      {HiredScheduleData.map((elem, index) => (
        <Grid key={index} item base={12} xs={12} sm={12} md={6} lg={6}>
          <HiredCandidateCard data={elem} />
        </Grid>
      ))}
    </Grid>
  );
};

export default HiredGrid;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DashboardSelect from "./DashboardSelect";
import theme from "../../theme/theme";
import { TbTableExport } from "react-icons/tb";
import { Snackbar, Tooltip } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const ChartCard = ({
  title,
  subTitle,
  menuItems,
  children,
  handleChange,
  isMobileRow = false,
  isMenuSelectShow = true,
  isOtherDropdown = false,
  menuItemsOther = [],
  handleOnClickExport,
  handleDurationChange,
}) => {
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  const handleDownload = async () => {
    try {
      await handleOnClickExport();
      setDownloadSuccess(true);
      setTimeout(() => {
        setDownloadSuccess(false);
      }, 3000);
    } catch (error) {
      console.error("Error downloading file:", error);
      // Handle error if necessary
    }
  };

  const handleSnackbarClose = () => {
    setDownloadSuccess(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          borderBottom: "1px solid",
          borderColor: theme.palette.primary.gray,
          width: "100%",
          padding: "12px 16px",
          display: "flex",
          flexDirection: { base: "column", lg: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { base: !isMobileRow ? "column" : "row", sm: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            gap: "12px",
          }}
        >
          <Box
            sx={{ width: { base: "100%", sm: children && "50%", md: "100%" } }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                fontWeight: "600",
                fontFamily: "Nunito,sans-serif",
                color: theme.palette.primary.black,
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: theme.palette.text.gray300,
              }}
            >
              {subTitle}
            </Typography>
          </Box>
          {children && children}
        </Box>
        <Box
          sx={{
            width: { base: "100%", lg: children && "50%" },
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: { base: "100%" } }}>
            {isMenuSelectShow && menuItems && (
              <DashboardSelect
                menuItems={menuItems}
                handleChange={handleChange}
              />
            )}
          </Box>
          {isOtherDropdown && (
            <Box
              sx={{ width: "100%", marginLeft: { base: "12px", md: "16px" } }}
            >
              <DashboardSelect
                menuItems={menuItemsOther}
                handleChange={handleDurationChange}
              />
            </Box>
          )}
          <Tooltip title="Export to Excel" arrow>
            <Box
              sx={{
                height: "24px",
                width: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                cursor: "pointer",
                marginLeft: "12px",
              }}
              onClick={handleDownload}
            >
              <TbTableExport
                size={22}
                color={theme.palette.secondary.main}
                fontWeight={400}
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Snackbar
        open={downloadSuccess}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="success"
        >
          Downloaded successfully !!
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ChartCard;

import React, { useState } from "react";
import CustomTable from "../common/customTable/CustomTable";
import { candidates_testtaken_table_rows } from "../../utils/data";
import {
  Box,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Snackbar,
  Alert as MuiAlert,
  Drawer,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SortingIcon from "../icons/SortingIcon"; // Make sure SortingIcon is correctly imported
import CheatingDetected from "../../components/cheatingDetected/CheatingDetected";
import { AuthContext } from "../../context/authContext";

const columnsWidth = [
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

const tableHeaderTitles = [
  {
    title: "Sr. no",
    cellStyleProps: {
      width: columnsWidth[0],
    },
  },
  {
    isSortCol: true,
    colId: "candidate",
    title: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
        >
          Candidate
        </Typography>
        <SortingIcon />
      </Box>
    ),
    cellStyleProps: {
      width: columnsWidth[0],
    },
  },
  {
    title: "Email ID",
    cellStyleProps: {
      width: columnsWidth[1],
    },
  },
  {
    isSortCol: true,
    colId: "score",
    title: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
        >
          Score
        </Typography>
        <SortingIcon />
      </Box>
    ),
    cellStyleProps: {
      width: columnsWidth[2],
    },
  },
  {
    title: "Section Score",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Status",
    cellStyleProps: {
      width: columnsWidth[4],
    },
  },
  {
    title: "Test Duration",
    cellStyleProps: {
      width: columnsWidth[5],
    },
  },
  {
    title: "",
    cellStyleProps: {
      width: columnsWidth[5],
    },
  },
];

const tableHeaderCells = () => {
  return tableHeaderTitles.map((item) => {
    return {
      isSortCol: item?.isSortCol ? item.isSortCol : false,
      colId: item?.colId ? item.colId : "",
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};

const statusTagColor = (id) => {
  if (id === 0) {
    return { color: "#004E3E", bg: "#D5F9F1" };
  } else if (id === 1) {
    return { color: "#733430", bg: "#FADCDA" };
  } else if (id === 2) {
    return { color: "#2B6DBA", bg: "#328DF61A" };
  } else {
    return { color: "#FF6812", bg: "#FFF0E7" };
  }
};

const TestTaken = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerHeading, setDrawerHeading] = useState("");
  const { authUser } = React.useContext(AuthContext);

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleViewDetails = () => {
    navigate("/talent_pool/cadidateDetails");
    handleMenuClose();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleGetLink = () => {
    const link =
      "https://staging.dev.theeliteqa.com/candidateresult/66694b7d69dfc4fef59e9df6";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setAlertMessage("Link copied to clipboard!");
        setSeverity("success");
        setOpen(true);
      })
      .catch(() => {
        setAlertMessage("Failed to copy the link.");
        setSeverity("error");
        setOpen(true);
      });
    handleMenuClose();
  };

  const handleCheatingClick = () => {
    // setDrawerHeading("View Snapshot");
    setDrawerOpen(true);
  };

  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.srNo}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src="/assets/people.png"
              alt="user"
              style={{ height: "20px", width: "20px" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
                cursor: "pointer",
              }}
              onClick={() => navigate("/talent_pool/cadidateDetails")}
            >
              {row.name}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.email}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.score}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.section_score}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: getStatus(row.status),
      },
      {
        cellStyleProps,
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.test_duration} mins
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: (
          <IconButton onClick={(event) => handleMenuClick(event, row)}>
            <img src="/assets/three_dots_icon.svg" alt="three dots" />
          </IconButton>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };
    return candidates_testtaken_table_rows.map((row, idx) => {
      return {
        sortable: { score: parseInt(row.score), candidate: row.name },
        rowElement: tableBodyRowCell({ ...row, srNo: idx + 1 }, cellStyleProps),
      };
    });
  };

  const getStatus = (status) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {status.cheated && (
          <Tooltip
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "40px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="bottom-start"
            arrow
            sx={{ background: "#fff" }}
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    color: "#E9EAEB",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                  onClick={handleCheatingClick}
                >
                  Cheating detected
                </Typography>
                <img src="/assets/redirect_icon_2.svg" alt="redirect icon" />
              </Box>
            }
          >
            <Box
              component="img"
              src="/assets/alert_icon.svg"
              alt="AlertIcon"
              sx={{ cursor: "pointer" }}
            />
          </Tooltip>
        )}
        <Box
          sx={{
            display: "inline-block",
            background: statusTagColor(status.id).bg,
            padding: "4px 12px",
            color: statusTagColor(status.id).color,
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            fontFamily: "Roboto",
            borderRadius: "0.25rem",
            whiteSpace: "nowrap",
          }}
        >
          {status.tag}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        tableHeaderCells={tableHeaderCells()}
        tableBodyContent={tableBodyContent()}
        tableData={candidates_testtaken_table_rows}
        sortRows={() => {}}
        onRowClick={() => {}}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleViewDetails}>
          <img
            style={{
              height: "20px",
              width: "22px",
              marginRight: "8px",
              color: "#000",
            }}
            src="/assets/eye copy.svg"
            alt="view details"
          />
          View Details
        </MenuItem>
        <MenuItem onClick={handleGetLink}>
          <img
            style={{
              marginRight: "8px",
            }}
            src="/assets/arrow-up.svg"
            alt="get link"
          />
          Get Result Link
        </MenuItem>
        {/* {authUser.role === "recruiter" && (
          <MenuItem>
            <img
              style={{ marginRight: "8px" }}
              src="/assets/icons/userCheck.svg"
              alt="shortlitsted"
            />
            Move to shortlisted
          </MenuItem>

        )} */}
      </Menu>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={severity}
        >
          {alertMessage}
        </MuiAlert>
      </Snackbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
        PaperProps={{
          sx: { width: "400px" },
        }}
      >
        <Box sx={{ padding: "16px" }}>
          <Typography variant="h6">{drawerHeading}</Typography>

          <CheatingDetected
            open={drawerOpen}
            setOpen={setDrawerOpen}
            drawerHeading="View Snapshots"
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default TestTaken;

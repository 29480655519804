import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { questions_tabs } from "../../utils/data";
import SectionTable from "../../components/questions/SectionTable";
import SkillsTable from "../../components/questions/SkillsTable";
import { useNavigate } from "react-router-dom";

const SectionHeader = ({ title, onClickViewQues }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          color: "#010101",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
          fontFamily: "Nunito",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          cursor: "pointer",
        }}
        onClick={onClickViewQues}
      >
        <img src="/assets/redirect_icon.svg" alt="redirect icon" />
        <Typography
          sx={{
            color: "#FF6812",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "400",
            fontFamily: "Roboto",
            padding: "0",
            minWidth: "auto",
          }}
        >
          View Questions
        </Typography>
      </Box>
    </Box>
  );
};

const Questions = () => {
  const navigate = useNavigate();
  const [value, setTabValue] = React.useState(questions_tabs[0].value);
  return (
    <Box
      sx={{
        width: "100%",
        background: "#fff",
      }}
    >
      <Box
        sx={{
          mt: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        {/* section wise */}
        <SectionHeader
          title="Section Wise"
          onClickViewQues={() => {
            navigate("/assessments/view-questions/Section");
          }}
        />
        <SectionTable />
        {/* skill  wise */}
        <SectionHeader
          title="Skill Wise"
          onClickViewQues={() => {
            navigate("/assessments/view-questions/Skill");
          }}
        />
        <SkillsTable />
      </Box>
    </Box>
  );
};

export default Questions;

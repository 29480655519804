function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";
const JOB_OPENINGS = "/job_openings";
const ASSESSMENTS = "/assessments";
const LIBRARY = "/library";
const CANDIDATES = "/candidates";
const INTERVIEWS = "/interviews";
const TALENT_POOL = "/talent_pool";
const SETTINGS = "/settings";
const ACCOUNT = "/account";
const QUESTIONS = "/assessments/analytics";

export const PATH_AUTH = {
  login: "/login",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  job_openings: JOB_OPENINGS,
  assessments: ASSESSMENTS,
  library: LIBRARY,
  candidates: CANDIDATES,
  interviews: INTERVIEWS,
  talent_pool: TALENT_POOL,
  settings: SETTINGS,
  account: ACCOUNT,
  questions: QUESTIONS,
};

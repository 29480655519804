import { Navigate, useRoutes } from "react-router-dom";
import {
  Account,
  AccountPage,
  Assessments,
  Candidates,
  Dashboard,
  Interviews,
  JobDetail,
  JobOpening,
  Library,
  Login,
  Settings,
  TalentPool,
  TalentPoolCandidateDetails,
  ViewQuestions,
  LogOut,
} from "./elements";
import DashboardLayout from "../layouts/DashboardLayout";
import { useContext, useState } from "react";
import { breadcrumbsContext } from "../context/breadcrumbsContext";
import { PATH_DASHBOARD } from "./paths";
import ShortlistedCandidates from "../pages/ShortlistedCandidates";
import AssessmentsAnalytics from "../pages/assessments/AssessmentsAnalytics";
import CheatingDetected from "../components/cheatingDetected/CheatingDetected";
import { AuthContext } from "../context/authContext";
import { navContext } from "../context/navContext";
import TemplateLibrary from "../pages/assessments/TemplateLibrary";

export default function Router() {
  const { isLoggedIn } = useContext(AuthContext);
  const { toggleDrawer, setToggleDrawer } = useContext(navContext);
  const [breadcrumbsData, setBreadcrumbsData] = useState([
    { name: "Dashboard", href: PATH_DASHBOARD.root },
  ]);

  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: isLoggedIn ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Login />
          ),
        },
      ],
    },
    {
      path: "/",
      element: isLoggedIn ? (
        <breadcrumbsContext.Provider
          value={{ breadcrumbsData, setBreadcrumbsData }}
        >
          <DashboardLayout />
        </breadcrumbsContext.Provider>
      ) : (
        <Navigate to="/auth/login" replace />
      ),
      children: [
        {
          element: <Navigate to={"/auth/login"} replace />,
          index: true,
        },
        {
          path: "logout",
          element: <LogOut />,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "job_openings",
          element: <JobOpening />,
        },
        {
          path: "job_openings/job_detail/:jobId",
          element: <JobDetail />,
        },
        {
          path: "assessments",
          element: <Assessments />,
        },
        {
          path: "assessments/analytics",
          element: <AssessmentsAnalytics />,
        },
        {
          path: "assessments/analytics/drawer",
          element: (
            <CheatingDetected
              open={toggleDrawer}
              setOpen={setToggleDrawer}
              drawerHeading="Cheating Detected "
            />
          ),
        },
        {
          path: "library",
          element: <Library />,
        },
        {
          path: "candidates",
          element: <Candidates />,
        },
        {
          path: "assessments/shortlistedcandidates",
          element: <ShortlistedCandidates />,
        },
        {
          path: "assessments/template_library",
          element: <TemplateLibrary />,
        },
        {
          path: "interviews",
          element: <Interviews />,
        },
        {
          path: "talent_pool",
          element: <TalentPool />,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "account",
          element: <AccountPage />,
        },
        {
          path: "talent_pool/cadidateDetails",

          element: <TalentPoolCandidateDetails />,
        },
        {
          path: "assessments/view-questions/:section",
          element: <ViewQuestions />,
        },

        {
          path: "view-questions/:section",
          element: <ViewQuestions />,
        },
      ],
    },
    // {
    //   element: <CompactLayout />,
    //   children: [{ path: "404", element: <Page404 /> }],
    // },
    // { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "./authContext";

export function AuthProvider({ children }) {
  const [authUser, setAuthUser] = useState(
    JSON.parse(localStorage.getItem("authUser")) || null
  );

  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("authUser")) !== null
  );

  useEffect(() => {
    const handleStorageChange = () => {
      const authUser = JSON.parse(localStorage.getItem("authUser"));
      const accessToken = JSON.parse(localStorage.getItem("accessToken"));

      if (!authUser || !accessToken) {
        window.location.reload();
      } else {
        setAuthUser(authUser);
        setIsLoggedIn(true);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const authContextValue = useMemo(
    () => ({
      isLoggedIn,
      setIsLoggedIn,
      authUser,
      setAuthUser,
    }),
    [isLoggedIn, authUser]
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CustomPagination from "./CustomPagination";

const CustomTable = ({
  tableContainerStyleProps,
  tableStyleProps,
  tableHeaderStyleProps,
  tableHeaderCells,
  tableBodyStyleProps,
  tableBodyContent,
  tableBodyRowStyleProps,
  tableHeaderAlign = "left",
  isPaginationVisible = true,
  tableHeaderCellStyleProps = {},
  totalPages,
  totalDocuments,
  limit,
  page,
  handleChangePage,
  handleRowsChange,
  rowsPerPageOptions,
}) => {
  const [renderedComponents, setRenderedComponents] = useState([]);
  // const [page, setPage] = useState(1);
  // const totalPages = Math.ceil(data.length / rows);
  // const totalRows = data.length;

  // useEffect(() => {
  //   const startIndex = (page - 1) * rows;
  //   const endIndex = startIndex + rows;
  //   setRenderedComponents(
  //     tableBodyContent && tableBodyContent?.slice(startIndex, endIndex)
  //   );
  //   console.log("tableBodyContent:", tableBodyContent);
  // }, [rows]);

  // const handleChangePage = (event, newPage) => {
  //   if (newPage !== page) {
  //     const startIndex = (newPage - 1) * rows;
  //     const endIndex = startIndex + rows;
  //     setPage(newPage);
  //     setRenderedComponents(tableBodyContent?.slice(startIndex, endIndex));
  //   }
  // };

  // const handleRowsChange = (newRows) => {
  //   if (newRows !== rows && newRows > 0) {
  //     setRows(newRows);
  //     const startIndex = page * newRows;
  //     const endIndex = startIndex + newRows;
  //     setRenderedComponents(tableBodyContent?.slice(startIndex, endIndex));
  //   }
  // };

  const sortData = (key) => {
    if (renderedComponents?.length > 0 && renderedComponents[0]?.sortable) {
      let sortedData = [...tableBodyContent];

      if (key === "candidate") {
        sortedData.sort((a, b) => {
          let nameA = a.sortable[key].toUpperCase(); // ignore upper and lowercase
          let nameB = b.sortable[key].toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } else {
        sortedData.sort((a, b) => a.sortable[key] - b.sortable[key]);
      }
      setRenderedComponents(sortedData);
    }
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      {/* table */}
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "none",
          "&::-webkit-scrollbar": {
            height: "4px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#E8E9EE",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#FF6812",
            borderRadius: "8px",
            width: "1px",
          },
          paddingY: "1rem",
          ...tableContainerStyleProps,
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            borderRadius: "0.25rem",
            border: "1px solid #E8E9EE",
            scrollbarColor: "#FF6812",
            ...tableStyleProps,
          }}
          size="small"
        >
          {/* table header */}
          <TableHead
            sx={{
              background: "#F2F4F8",
              color: "#363E45",
              lineHeight: "16px",
              fontWeight: "600",
              fontSize: "12px",
              padding: "8px 16px",
              borderBottom: "1px solid #E8E9EE",
              ...tableHeaderStyleProps,
            }}
          >
            <TableRow>
              {tableHeaderCells.map((cell, idx) => {
                return (
                  <TableCell
                    key={idx}
                    align={tableHeaderAlign}
                    sx={{
                      ...tableHeaderCellStyleProps,
                      ...cell?.cellStyleProps,
                      cursor: cell.isSortCol ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (cell.isSortCol) {
                        sortData(cell.colId);
                      }
                    }}
                  >
                    {cell.element}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {/* table body */}
          <TableBody sx={{ ...tableBodyStyleProps }}>
            {tableBodyContent?.length > 0 ? (
              tableBodyContent.map((tableBodyRow, idx) => (
                <TableRow
                  key={idx}
                  sx={{
                    borderBottom: "1px solid #E8E9EE",
                    ...tableBodyRowStyleProps,
                  }}
                >
                  {tableBodyRow.rowElement.map((tableBodyRowCell, idx) => (
                    <TableCell
                      key={idx}
                      sx={{ ...tableBodyRowCell?.cellStyleProps }}
                    >
                      {tableBodyRowCell.element}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* table pagination  */}
      {isPaginationVisible && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalDocuments}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={(e, newPage) => {
            handleChangePage(newPage + 1);
          }}
          onRowsPerPageChange={handleRowsChange}
          className="custom-table-pagination"
        />
      )}
    </Box>
  );
};

export default CustomTable;

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import {
  CandidateCheckboxData,
  TestDurationData,
  initialCheckboxData,
  instructions,
} from "../../utils/data";

const CommonButton = (props) => {
  return (
    <Typography
      sx={{
        padding: "4px 8px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#384455",
        backgroundColor: "#D3D9E2",
        borderRadius: "4px",
      }}
      {...props}
    >
      {props.children}
    </Typography>
  );
};

const TestOverview = () => {
  const theme = useTheme();
  const [checkboxData, setCheckboxData] = useState();
  const [candidatecheckbox, setCandidateCheckbox] = useState();

  const handleCheckboxChange = (item, checkboxType) => (event) => {
    const updatedCheckboxData =
      checkboxType === "initial"
        ? initialCheckboxData.slice()
        : CandidateCheckboxData.slice();
    updatedCheckboxData.forEach((checkboxItem) => {
      if (checkboxItem.label === item.label) {
        checkboxItem.checked = event.target.checked;
      }
    });
    if (checkboxType === "initial") {
      setCheckboxData(updatedCheckboxData);
    } else {
      setCandidateCheckbox(updatedCheckboxData);
    }
  };
  return (
    <Box
      sx={{
        marginTop: "1rem",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0px 0px 20px",
        gap: "20px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #E8E9EE",
        borderRadius: "4px",
      }}
    >
      {/* Overview topcard */}
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        sx={{
          padding: "16px 20px",
          gap: "20px",
          alignSelf: "stretch",
          background: "#F2F4F8",
          borderRadius: "4px",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{
            padding: 0,
            gap: "16px",
            alignSelf: "stretch",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "24px",
              color: "#363E45",
              flexGrow: 1,
            }}
          >
            Test Overview
          </Typography>

          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"8px"}
            sx={{ padding: 0 }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={"8px"}
              sx={{ padding: 0 }}
            >
              {/* <IconButton aria-label="delete" size="small" sx={{ padding: 0 }}>
                <Box
                  component={"img"}
                  src="/assets/avatar_icon.svg"
                  alt="avatar icon"
                  sx={{ width: "20px", height: "20px", color: "#8591A2" }}
                ></Box>
              </IconButton> */}
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#8591A2",
                }}
              >
                <CommonButton>Public</CommonButton>
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{
                padding: " 4px 8px",
                gap: " 4px",
                backgroundColor: "#FFF0E7",
                borderRadius: "4px",
              }}
            >
              <IconButton aria-label="delete" size="small" sx={{ padding: 0 }}>
                <Box
                  component={"img"}
                  src="/assets/icons/mailForwardOrange.svg"
                  alt="avatar icon"
                  sx={{ width: "20px", height: "20px", color: "#FF6812" }}
                ></Box>
              </IconButton>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#FF6812",
                }}
              >
                Invite Only
              </Typography>
            </Stack>
            <CommonButton>Hire360 Library</CommonButton>
            <CommonButton>My Library</CommonButton>
          </Stack>

          <Button
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 16px 10px 12px",
              gap: "8px",
              border: "1px solid #FF6812",
              borderRadius: "4px",
              height: "30px",
              width: "165px",
            }}
          >
            <Box
              component={"img"}
              src="/assets/icons/copyOrange.svg"
              alt="copy icon"
              sx={{ width: "20px", height: "15px" }}
            ></Box>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
                color: "#FF6812",
              }}
            >
              Assessment Link
            </Typography>
          </Button>
        </Stack>

        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          gap={"16px"}
          sx={{ padding: 0 }}
          divider={
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderColor: "#BBC2CC" }}
            />
          }
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#363E45",
            }}
          >
            This test is to test your JavaScript loop skills with our quick
            assessment. Complete coding challenges to showcase your proficiency
            in for, while, and do-while loops. Good luck
          </Typography>
          <Stack
            direction={"column"}
            alignItems={"flex-start"}
            gap={"12px"}
            sx={{ padding: 0 }}
            alignSelf={"stretch"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={"12px"}
              sx={{ padding: 0, alignSelf: "stretch" }}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {TestDurationData.slice(0, 3)?.map((event, index) => (
                <Stack
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  gap={"8px"}
                  sx={{
                    padding: 0,
                    alignSelf: "stretch",
                    flexGrow: 1,
                    width: "33.33%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={event.iconSrc}
                    sx={{ fill: "#8591A2" }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    sx={{ padding: 0 }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#8C9194",
                      }}
                    >
                      {event.label}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#363E45",
                      }}
                    >
                      {event.duration}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={"12px"}
              sx={{ padding: 0, alignSelf: "stretch" }}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {TestDurationData.slice(3, 6)?.map((event, index) => (
                <Stack
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  gap={"8px"}
                  sx={{
                    padding: 0,
                    alignSelf: "stretch",
                    flexGrow: 1,
                    width: "33.33%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={event.iconSrc}
                    sx={{ fill: "#8591A2" }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    sx={{ padding: 0 }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#8C9194",
                      }}
                    >
                      {event.label}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#363E45",
                      }}
                    >
                      {event.duration}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* secondary section */}
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        sx={{
          padding: "1px 20px 20px 20px",
          gap: "20px",
          alignSelf: "stretch",
          overflowY: "scroll",
          borderRadius: "4px",
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          sx={{
            padding: "0",
            gap: "24px",
            border: " 1px solid #E8E9EE",
            borderRadius: "4px",
            alignSelf: "stretch",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"flex-start"}
            sx={{
              padding: "0px 0px 20px",
              alignSelf: "stretch",
              flexGrow: 1,
            }}
          >
            <FlexContainer>
              <Stack
                direction={"column"}
                alignItems={"flex-start"}
                sx={{
                  padding: " 0px 16px",
                  gap: "20px",
                  alignSelf: "stretch",
                  borderRight: " 1px solid #E8E9EE",
                  flexGrow: 1,
                }}
              >
                <IconTextStack
                  src="/assets/icons/settings.svg"
                  text="Proctoring Settings"
                />

                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  {initialCheckboxData?.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={item.checked}
                          disabled={true}
                          onChange={handleCheckboxChange(item, "initial")}
                          size="small"
                          //   sx={{ padding:'0' }}
                          sx={{
                            paddingY: "0",
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#363E45",
                          }}
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </Stack>
            </FlexContainer>
            <FlexContainer>
              <Stack
                direction={"column"}
                alignItems={"flex-start"}
                sx={{
                  padding: " 0px 16px",
                  gap: "20px",
                  alignSelf: "stretch",
                  flexGrow: 1,
                }}
              >
                <IconTextStack
                  src="/assets/icons/userCircle.svg"
                  text="Candidate Settings"
                />

                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  {CandidateCheckboxData?.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={item.checked}
                          disabled={true}
                          onChange={handleCheckboxChange(item, "candidate")}
                          size="small"
                          //   sx={{ padding:'0' }}
                          sx={{
                            paddingY: "0",
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#363E45",
                          }}
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </Stack>
            </FlexContainer>
          </Stack>
        </Stack>

        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          sx={{
            padding: "0px 16px 12px",
            gap: "12px",
            border: "1px solid #E8E9EE",
            borderRadius: "4px",
            alignSelf: "stretch",
            flexGrow: 1,
          }}
        >
          <IconTextStack
            src="/assets/icons/checkupListBlack.svg"
            text=" Test Instructions"
          />

          <Box sx={{ padding: " 0px 20px" }}>
            <List>
              {instructions?.map((instruction, index) => (
                <ListItem key={index} sx={{ padding: 0, margin: 0 }}>
                  <ListItemText
                    primary={`${index + 1}. ${instruction}`}
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "28px",
                      color: theme.palette.primary.black,
                      "&": {
                        margin: 0, // Remove top and bottom margins
                        color: theme.palette.primary.black,
                      },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Stack>

        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          sx={{
            padding: "0px 16px 12px",
            gap: "12px",
            border: "1px solid #E8E9EE",
            borderRadius: "4px",
            alignSelf: "stretch",
            flexGrow: 1,
          }}
        >
          <IconTextStack src="/assets/icons/users24.svg" text="Test Admins" />
        </Stack>
      </Stack>
    </Box>
  );
};

export default TestOverview;

// Common Stack with Icon and Typography Component
const IconTextStack = ({ src, text }) => (
  <Stack
    direction={"row"}
    alignItems={"center"}
    sx={{
      gap: "12px",
      borderBottom: "1px solid #E9EAEB",
      alignSelf: "stretch",
    }}
  >
    <Box
      component={"img"}
      src={src}
      alt="icon"
      width={"24px"}
      height={"24px"}
    />
    <Typography
      sx={{
        padding: "12px 0px 12px 0px",
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "20px",
        color: "#363E45",
      }}
    >
      {text}
    </Typography>
  </Stack>
);
const FlexContainer = ({ children }) => (
  <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
    {children}
  </div>
);

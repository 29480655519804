import React from "react";

const SortingIcon = () => {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.16683 1.58497H1.3335C1.17875 1.58497 1.03033 1.52349 0.920903 1.41407C0.811476 1.30464 0.75 1.15622 0.75 1.00147C0.75 0.846715 0.811476 0.698299 0.920903 0.588872C1.03033 0.479444 1.17875 0.417969 1.3335 0.417969H7.16683C7.32159 0.417969 7.47 0.479444 7.57943 0.588872C7.68886 0.698299 7.75033 0.846715 7.75033 1.00147C7.75033 1.15622 7.68886 1.30464 7.57943 1.41407C7.47 1.52349 7.32159 1.58497 7.16683 1.58497Z"
        fill="#71777B"
      />
      <path
        d="M7.16683 6.58497H1.3335C1.17875 6.58497 1.03033 6.52349 0.920903 6.41407C0.811476 6.30464 0.75 6.15622 0.75 6.00147C0.75 5.84671 0.811476 5.6983 0.920903 5.58887C1.03033 5.47944 1.17875 5.41797 1.3335 5.41797H7.16683C7.32159 5.41797 7.47 5.47944 7.57943 5.58887C7.68886 5.6983 7.75033 5.84671 7.75033 6.00147C7.75033 6.15622 7.68886 6.30464 7.57943 6.41407C7.47 6.52349 7.32159 6.58497 7.16683 6.58497Z"
        fill="#71777B"
      />
      <path
        d="M8.8335 11.585H1.3335C1.17875 11.585 1.03033 11.5235 0.920903 11.4141C0.811476 11.3046 0.75 11.1562 0.75 11.0015C0.75 10.8467 0.811476 10.6983 0.920903 10.5889C1.03033 10.4794 1.17875 10.418 1.3335 10.418H8.8335C8.98825 10.418 9.13667 10.4794 9.2461 10.5889C9.35552 10.6983 9.417 10.8467 9.417 11.0015C9.417 11.1562 9.35552 11.3046 9.2461 11.4141C9.13667 11.5235 8.98825 11.585 8.8335 11.585Z"
        fill="#71777B"
      />
      <path
        d="M15.5002 4.08496C15.4235 4.08502 15.3476 4.06996 15.2768 4.04063C15.206 4.01131 15.1417 3.96829 15.0876 3.91406L13.0002 1.82625L10.9128 3.91406C10.8032 4.02284 10.655 4.08376 10.5006 4.08348C10.3462 4.0832 10.1982 4.02174 10.089 3.91257C9.97988 3.8034 9.91842 3.65541 9.91814 3.50102C9.91786 3.34663 9.97878 3.19843 10.0876 3.08886L12.5876 0.588861C12.697 0.47944 12.8454 0.417969 13.0002 0.417969C13.1549 0.417969 13.3033 0.47944 13.4128 0.588861L15.9128 3.08886C15.9944 3.17047 16.0499 3.27444 16.0724 3.38762C16.095 3.50081 16.0834 3.61814 16.0392 3.72476C15.9951 3.83138 15.9203 3.92251 15.8243 3.98663C15.7284 4.05074 15.6156 4.08496 15.5002 4.08496Z"
        fill="#71777B"
      />
      <path
        d="M13.0002 11.585C12.9235 11.585 12.8477 11.5699 12.7769 11.5406C12.7061 11.5112 12.6417 11.4683 12.5876 11.4141C12.5334 11.3599 12.4904 11.2956 12.4611 11.2248C12.4318 11.154 12.4167 11.0781 12.4167 11.0015V1.00147C12.4167 0.846715 12.4781 0.698289 12.5876 0.588861C12.697 0.47944 12.8454 0.417969 13.0002 0.417969C13.1549 0.417969 13.3033 0.47944 13.4128 0.588861C13.5222 0.698289 13.5837 0.846715 13.5837 1.00147V11.0015C13.5837 11.0781 13.5686 11.154 13.5393 11.2248C13.5099 11.2956 13.4669 11.3599 13.4128 11.4141C13.3586 11.4683 13.2943 11.5112 13.2235 11.5406C13.1527 11.5699 13.0768 11.585 13.0002 11.585Z"
        fill="#71777B"
      />
    </svg>
  );
};

export default SortingIcon;

import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../common/SearchBar";

const SearchHeader = () => {
  const [channel, setChannel] = useState("All");

  const handleChange = (event) => {
    setChannel(event.target.value);
  };

  return (
    <Box>
      {/* for dektop */}
      <Grid
        container
        spacing={2}
        sx={{ mt: "0.1rem", display: { base: "none", md: "flex" } }}
      >
        <Grid item base={9} lg={10}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#010101",
                fontFamily: "Nunito",
              }}
            >
              Candidates (100)
            </Typography>
            <SearchBar placeholder="Search Candidate" width="296px" />
          </Box>
        </Grid>
        <Grid item base={3} lg={2}>
          <FormControl
            fullWidth
            sx={{
              backgroundColor: "#fff",
              height: "40px",
            }}
          >
            <InputLabel
              sx={{
                letterSpacing: "0.5px",
              }}
            >
              Status
            </InputLabel>
            <Select
              sx={{
                "& .select-padding": {
                  padding: "7px 8px", // Adjust padding as needed
                },
                height: "40px",
              }}
              value={channel}
              label="chanel"
              onChange={handleChange}
              inputProps={{ className: "select-padding" }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Passed">Passed</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
              <MenuItem value="Shortlisted">Shortlisted</MenuItem>
              <MenuItem value="Reviewed">Reviewed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* for tablet/mobile */}
      <Box
        sx={{
          my: "1rem",
          ml: "0.25rem",
          display: { base: "block", md: "none" },
        }}
      >
        <Typography
          sx={{
            whiteSpace: "nowrap",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#010101",
            fontFamily: "Nunito",
          }}
        >
          Candidates
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ display: { base: "flex", md: "none" } }}
      >
        <Grid item base={12} sm={9}>
          <SearchBar placeholder="Search" width="100%" />
        </Grid>
        <Grid item base={12} sm={3}>
          <FormControl
            fullWidth
            sx={{
              backgroundColor: "#fff",
              height: "40px",
            }}
          >
            <InputLabel
              sx={{
                letterSpacing: "0.5px",
              }}
            >
              Status
            </InputLabel>
            <Select
              sx={{
                "& .select-padding": {
                  padding: "10px 8px", // Adjust padding as needed
                },
                height: "40px",
              }}
              value={channel}
              label="chanel"
              onChange={handleChange}
              inputProps={{ className: "select-padding" }}
            >
              <MenuItem value="All">All</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchHeader;

import { Box, Button, TextField } from "@mui/material";
import React from "react";

const C = ({
  page,
  handleChangePage,
  handleRowsChange,
  totalPages,
  rows,
  totalDocuments,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { base: "flex-start", sm: "flex-end" },
        marginY: "1rem",
      }}
      className="custom-table-pagination"
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <Box
          sx={{
            height: "28px",
            width: "28px",
            borderRadius: "0.25rem",
            padding: "6px",
            border: "1px solid #D3D9E2",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            handleChangePage(Event, 1);
          }}
        >
          <img src="/assets/doubleChevronsLeft.svg" alt="chevron" />
        </Box>
        <Box
          sx={{
            height: "28px",
            width: "28px",
            borderRadius: "0.25rem",
            padding: "6px",
            border: "1px solid #D3D9E2",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            if (page > 1) {
              handleChangePage(Event, page - 1);
            }
          }}
        >
          <img src="/assets/chevronLeft.svg" alt="chevron" />
        </Box>
        <TextField
          value={totalDocuments < rows ? totalDocuments : rows}
          onChange={(e) => {
            handleRowsChange(e.target.value);
          }}
          type="number"
          sx={{
            "& fieldset": {
              border: "none",
              textAlign: "center",
            },
            "& input": {
              border: "1px solid #E8E9EE",
              width: "38px",
              height: "28px",
              padding: "0px 4px 0px 8px",
              borderRadius: "0.25rem",
              fontSize: "12px",
              fontFamily: "Roboto",
              lineHeight: "16px",
              fontWeight: "400",
              color: "#363E45",
            },
          }}
        />{" "}
        of {totalDocuments}
        <Box
          sx={{
            height: "28px",
            width: "28px",
            borderRadius: "0.25rem",
            padding: "6px",
            border: "1px solid #D3D9E2",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            if (page < totalPages) {
              handleChangePage(Event, page + 1);
            }
          }}
        >
          <img src="/assets/chevronRight.svg" alt="chevron" />
        </Box>
        <Box
          sx={{
            height: "28px",
            width: "28px",
            borderRadius: "0.25rem",
            padding: "6px",
            border: "1px solid #D3D9E2",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            handleChangePage(Event, totalPages);
          }}
        >
          <img src="/assets/doubleChevronsRight.svg" alt="chevron" />
        </Box>
      </Box>
    </Box>
  );
};

export default C;

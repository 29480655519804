import PropTypes from "prop-types";
import { memo } from "react";
// @mui
import { Box } from "@mui/material";

Scrollbar.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

function Scrollbar({ children, sx, ...other }) {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );

  if (isMobile) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowX: "auto",
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowX: "auto",
        ...sx,
      }}
      {...other}
    >
      {/* <StyledRootScrollbar> */}
      {/* <StyledScrollbar clickOnTrack={false} sx={sx} {...other}> */}
      {children}
      {/* </StyledScrollbar> */}

      {/* </StyledRootScrollbar> */}
    </Box>
  );
}

export default memo(Scrollbar);

import { Box, Tooltip } from "@mui/material";
import { PATH_DASHBOARD } from "./routes/paths";

const icon = (name) => (
  <Box
    component={"img"}
    src={`/assets/icons/${name}.svg`}
    sx={{ width: "24px", height: "24px" }}
  />
);

const ICONS = {
  dashboard: icon("I_dashboard"),
  a_dashboard: icon("A_I_dashboard"),
  jobopen: icon("I_jobopen"),
  a_jobopen: icon("A_I_jobopen"),
  assessment: icon("I_assessment"),
  a_assessment: icon("A_I_assessment"),
  library: icon("I_library"),
  a_library: icon("A_I_library"),
  candidates: icon("I_candidate"),
  a_candidates: icon("A_I_candidate"),
  interview: icon("I_interview"),
  a_interview: icon("A_I_interview"),
  talentpool: icon("I_talentpool"),
  a_talentpool: icon("A_I_talentpool"),
  settings: icon("I_settings"),
  a_settings: icon("A_I_settings"),
};

const navConfig = [
  {
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.root,
        icon: (
          <Tooltip
            title="Dashboard"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.dashboard}
          </Tooltip>
        ),
        activeIcon: (
          <Tooltip
            title="Dashboard"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  color: "#313E4F", // Ensure this matches the tooltip background color
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.a_dashboard}
          </Tooltip>
        ),
      },
      {
        title: "Job Openings",
        path: PATH_DASHBOARD.job_openings,
        icon: (
          <Tooltip
            title="Job Openings"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "95px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.jobopen}
          </Tooltip>
        ),
        activeIcon: (
          <Tooltip
            title="Job Openings"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "95px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.a_jobopen}
          </Tooltip>
        ),
      },
      {
        title: "Assessments",
        path: PATH_DASHBOARD.assessments,
        icon: (
          <Tooltip
            title="Assessments"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "90px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.assessment}
          </Tooltip>
        ),
        activeIcon: (
          <Tooltip
            title="Assessments"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "90px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.a_assessment}
          </Tooltip>
        ),
      },
      {
        title: "Library",
        path: PATH_DASHBOARD.library,
        icon: (
          <Tooltip
            title="Library"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.library}
          </Tooltip>
        ),
        activeIcon: (
          <Tooltip
            title="Library"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.a_library}
          </Tooltip>
        ),
      },
      {
        title: "Candidates",
        path: PATH_DASHBOARD.candidates,
        icon: (
          <Tooltip
            title="Candidates"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.candidates}
          </Tooltip>
        ),
        activeIcon: (
          <Tooltip
            title="Candidates"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.a_candidates}
          </Tooltip>
        ),
      },
      {
        title: "Interviews",
        path: PATH_DASHBOARD.interviews,
        icon: (
          <Tooltip
            title="Interviews"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.interview}
          </Tooltip>
        ),
        activeIcon: (
          <Tooltip
            title="Interviews"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.a_interview}
          </Tooltip>
        ),
      },
      {
        title: "Talent Pool",
        path: PATH_DASHBOARD.talent_pool,
        icon: (
          <Tooltip
            title="Talent Pool"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.talentpool}
          </Tooltip>
        ),
        activeIcon: (
          <Tooltip
            title="Talent Pool"
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "#313E4F",
                  height: "30px",
                  width: "80px",
                  padding: "8px 12px",
                  borderRadius: "0.25rem",
                },
                "& .MuiTooltip-arrow": {
                  // background: "#313E4F",
                },
              },
            }}
            placement="right"
            arrow
          >
            {ICONS.a_talentpool}
          </Tooltip>
        ),
      },
      // {
      //   title: "Settings",
      //   path: PATH_DASHBOARD.settings,
      //   icon: (
      //     <Tooltip
      //       title="Settings"
      //       PopperProps={{
      //         sx: {
      //           "& .MuiTooltip-tooltip": {
      //             display: "flex",
      //             alignItems: "center",
      //             bgcolor: "#313E4F",
      //             height: "30px",
      //             width: "80px",
      //             padding: "8px 12px",
      //             borderRadius: "0.25rem",
      //           },
      //           "& .MuiTooltip-arrow": {
      //             // background: "#313E4F",
      //           },
      //         },
      //       }}
      //       placement="right"
      //       arrow
      //     >
      //       {ICONS.settings}
      //     </Tooltip>
      //   ),
      //   activeIcon: (
      //     <Tooltip
      //       title="Settings"
      //       PopperProps={{
      //         sx: {
      //           "& .MuiTooltip-tooltip": {
      //             display: "flex",
      //             alignItems: "center",
      //             bgcolor: "#313E4F",
      //             height: "30px",
      //             width: "80px",
      //             padding: "8px 12px",
      //             borderRadius: "0.25rem",
      //           },
      //           "& .MuiTooltip-arrow": {
      //             // background: "#313E4F",
      //           },
      //         },
      //       }}
      //       placement="right"
      //       arrow
      //     >
      //       {ICONS.a_settings}
      //     </Tooltip>
      //   ),
      // },
    ],
  },
];
export default navConfig;

import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import TabsList from "../../components/candidateShortlisted/Tabs";
import { candidates_tabs_one } from "../../utils/data";
import Analytics from "../../components/assessmentAnalytics/Analytics";
import Header from "../../components/candidateShortlisted/Header";
import Candidates from "./Candidates";
import Questions from "./Questions";
import { breadcrumbsContext } from "../../context/breadcrumbsContext";
import { PATH_DASHBOARD } from "../../routes/paths";
import AnalyticsHeader from "../../components/assessmentAnalytics/AnalyticsHeader";
import TestOverview from "./TestOverview";

function AssessmentsAnalytics() {
  const [tabsOneValue, setTabsOneValue] = React.useState(
    candidates_tabs_one[0].value
  );
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);
  const location = useLocation();

  const tabNames = {
    testoverview: "Test Overview",
    questions: "Questions",
    candidates: "Candidates",
    analytics: "Analytics",
  };

  useEffect(() => {
    if (location.state && location.state.tabValue) {
      setTabsOneValue(location.state.tabValue);
    }
  }, [location.state]);

  useEffect(() => {
    setBreadcrumbsData([
      { name: "Assessments", href: PATH_DASHBOARD.assessments },
      { name: tabNames[tabsOneValue] },
    ]);
  }, [tabsOneValue]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#F2F4F8",
        display: "flex",
      }}
    >
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            height: "100%",
            background: "#F2F4F8",
            width: "100%",
          }}
        >
          <Box sx={{ my: "10px" }}>
            <AnalyticsHeader />
          </Box>

          <Box
            sx={{
              width: "100%",
              margin: "16px 0",
              background: "#fff",
              padding: "0.5rem 1rem",
              border: "1px solid #E8E9EE",
              borderRadius: "0.25rem",
              marginTop: "6px",
            }}
          >
            <TabsList
              value={tabsOneValue}
              tabs={candidates_tabs_one}
              handleTabChange={setTabsOneValue}
            />

            <Box>
              {tabsOneValue === "testoverview" && <TestOverview />}
              {tabsOneValue === "questions" && <Questions />}
              {tabsOneValue === "candidates" && <Candidates />}
              {tabsOneValue === "analytics" && <Analytics />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AssessmentsAnalytics;

import React, { useContext, useEffect, useState } from 'react'
import { breadcrumbsContext } from '../../context/breadcrumbsContext';
import { PATH_DASHBOARD } from '../../routes/paths';
import { Box, Stack, Button, IconButton,  Grid, Tabs, Tab } from "@mui/material";
import SearchBar from '../../components/common/SearchBar';
import Iconify from '../../components/iconify/Iconify';
import ReadymadeTestCard from '../../components/common/ReadymadeTestCard';
import { makeStyles } from "@mui/styles";
import { AuthContext } from '../../context/authContext';
import TabsList from '../../components/candidateShortlisted/Tabs';

const useStyles = makeStyles((theme) => ({
  tabHeader: {
    marginBottom: "16px",
    "& button[aria-selected='true']": {
      color: theme.palette.green.secondaryGreen,
    },
    "& .MuiTabs-indicator": {
      background: theme.palette.green.secondaryGreen,
    },
  },
  tableCard: {
    borderRadius: "4px",
    padding: "16px",
    background: theme.palette.background.default,
    width: "100%",
  },
}));

  const tabList = [
    { label: "All", value: "all" },
    { label: "Mern Developer", value: "mern" },
    { label: "AWS Manager", value: "aws" },
    { label: "Database Engineer", value: "database" },
    { label: "QA Tester", value: "qa" },
    { label: "Backend Web Developer", value: "backend" },
    { label: "Automation Tester", value: "automation" },
    { label: "Software Engineer", value: "software" },
    { label: "Frontend web developer", value: "fronted" },
  ];

const buttons = [
  { key: "one", text: "Easy", imgSrc: "/assets/icons/greenFireEmpty.svg" },
  { key: "two", text: "Medium", imgSrc: "/assets/icons/orangeFireEmpty.svg" },
  { key: "three", text: "Hard", imgSrc: "/assets/icons/redFireEmpty.svg" },
];


const TemplateLibraryData = [
  {
    id: "1",
    title: "Javascript Loops Test",
    tag: ["React", "MongoD", "Node.js", "Next.js"],
    description:
      "Readymade template for JavaScript basic question assessment with medium level of difficulty Readymade template for JavaScript basic question asses level of difficulty",
    difficultyLevel: { level: "easy" },
    time: 30,
    questions: 20,
    score: 100,
    cutOff: 15,
  },
  {
    id: "2",
    title: "Javascript Loops Test",
    tag: ["React", "MongoD", "Node.js", "Next.js"],
    description:
      "Readymade template for JavaScript basic question assessment with medium level of difficulty Readymade template for JavaScript basic question asses level of difficulty",
    difficultyLevel: { level: "medium" },
    time: 30,
    questions: 20,
    score: 100,
    cutOff: 15,
  },
  {
    id: "3",
    title: "Javascript Loops Test",
    tag: ["React", "MongoD", "Node.js", "Next.js"],
    description:
      "Readymade template for JavaScript basic question assessment with medium level of difficulty Readymade template for JavaScript basic question asses level of difficulty",
    difficultyLevel: { level: "hard" },
    time: 30,
    questions: 20,
    score: 100,
    cutOff: 15,
  },
  {
    id: "4",
    title: "Javascript Loops Test",
    tag: ["React", "MongoD", "Node.js", "Next.js"],
    description:
      "Readymade template for JavaScript basic question assessment with medium level of difficulty Readymade template for JavaScript basic question asses level of difficulty",
    difficultyLevel: { level: "hard" },
    time: 30,
    questions: 20,
    score: 100,
    cutOff: 15,
  },
  {
    id: "5",
    title: "Javascript Loops Test",
    tag: ["React", "MongoD", "Node.js", "Next.js"],
    description:
      "Readymade template for JavaScript basic question assessment with medium level of difficulty Readymade template for JavaScript basic question asses level of difficulty",
    difficultyLevel: { level: "hard" },
    time: 30,
    questions: 20,
    score: 100,
    cutOff: 15,
  },
  {
    id: "6",
    title: "Javascript Loops Test",
    tag: ["React", "MongoD", "Node.js", "Next.js"],
    description:
      "Readymade template for JavaScript basic question assessment with medium level of difficulty Readymade template for JavaScript basic question asses level of difficulty",
    difficultyLevel: { level: "hard" },
    time: 30,
    questions: 20,
    score: 100,
    cutOff: 15,
  },
  {
    id: "7",
    title: "Javascript Loops Test",
    tag: ["React", "MongoD", "Node.js", "Next.js"],
    description:
      "Readymade template for JavaScript basic question assessment with medium level of difficulty Readymade template for JavaScript basic question asses level of difficulty",
    difficultyLevel: { level: "hard" },
    time: 30,
    questions: 20,
    score: 100,
    cutOff: 15,
  },
];

const TemplateLibrary = () => {
   const [value, setTabValue] = useState(tabList[0].value);
const { authUser } = useContext(AuthContext);
    const [search, setSearch] = useState("");
    const { setBreadcrumbsData } = useContext(breadcrumbsContext);
   
     useEffect(() => {
       const currentTab = tabList.find((tab) => tab.value === value);
       setBreadcrumbsData([
         { name: "Assessments", href: PATH_DASHBOARD.interviews },
         { name: currentTab.label },
       ]);
     }, [value, setBreadcrumbsData]);


  return (
    <Box sx={{ display: "flex", gap: "12px", flexDirection: "column" }}>
      {authUser?.role === "recruiter" && (
        <Box >
          {/* tabs */}
          <TabsList
            tabs={tabList}
            handleTabChange={setTabValue}
            value={value}
          />
        </Box>
      )}
{value === 'all'  && 
      <Stack
        gap={"16px"}
        sx={{
          padding: "16px",
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
        }}
        flexDirection={"column"}
      >
        <Stack justifyContent={"space-between"} direction={"row"}>
          <Box sx={{ display: "flex", gap: "12px" }}>
            {buttons.map((button, index) => (
              <Button
                key={button.key}
                sx={{
                  padding: "8px 12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  borderRadius: "4px",
                  border: "1px solid #E8E9EE",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  height: "36px",
                }}
                color="inherit"
              >
                <Box
                  component="img"
                  src={button.imgSrc}
                  alt={`${button.text} icon`}
                />
                {button.text}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: "flex", gap: "12px" }}>
            <SearchBar search={search} setSearch={setSearch} />
            <Box
              sx={{ display: { md: "block", base: "none" }, cursor: "pointer" }}
            >
              <IconButton
                sx={{
                  width: "36px",
                  height: "36px",
                  border: "1px solid",
                  borderRadius: "4px",
                  padding: 0,
                  borderColor: "secondary.main",
                }}
              >
                <Iconify
                  icon="mage:filter"
                  width={"16px"}
                  color={"secondary.main"}
                />
              </IconButton>
            </Box>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          {TemplateLibraryData.map((template, index) => (
            <ReadymadeTestCard template={template} />
          ))}
        </Grid>
      </Stack>
      }
    </Box>
  );
}

export default TemplateLibrary
import React, { useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  selectBox: {
    width: "100%",
    color: `${theme.palette.secondary.main} !important`,
    fontSize: "14px",
  },
  selectBoxDefault: {
    width: "100%",
    color: "#000",
    fontSize: "14px",
  },
  centeredLabel: {
    textAlign: "center",
  },
}));

const DashboardSelect = ({ title, menuItems, handleChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <FormControl fullWidth size="small">
        <InputLabel
          id="demo-simple-select-label"
          className={classes.centeredLabel}
        >
          {title}
        </InputLabel>
        <Select
          size="small"
          defaultValue={menuItems?.[0].value}
          labelId="demo-simple-select-label"
          className={classes.selectBox}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          label={title}
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={() =>
            open ? (
              <Box
                component={"img"}
                sx={{ rotate: "180deg", marginRight: "12px" }}
                src={"/assets/icons/chevronDown.svg"}
              />
            ) : (
              <Box
                component={"img"}
                sx={{ marginRight: "12px" }}
                src={"/assets/icons/chevronDown.svg"}
              />
            )
          }
          onChange={(e) => handleChange(e.target.value)}
        >
          {menuItems.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DashboardSelect;

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TabsList from "../../components/candidateShortlisted/Tabs";
import Header from "../../components/candidateShortlisted/Header";
import { candidates_tabs_one, candidates_tabs_two } from "../../utils/data";
import theme from "../../theme/theme";
import SearchTextField from "../../components/searchTextField/SearchTextField";
import TestTaken from "../../components/candidates/TestTaken";
import Shortlisted from "../../components/candidates/Shortlisted";
import Passed from "../../components/candidates/Passed";
import Reviewed from "../../components/candidates/Reviewed";
import Invited from "../../components/candidates/Invited";
import TestTakenSearchHeader from "../../components/candidates/TestTakenSearchHeader";
import SearchHeader from "../../components/candidates/SearchHeader";
import { breadcrumbsContext } from "../../context/breadcrumbsContext";

const Candidates = () => {
  const [tabsOneValue, setTabsOneValue] = React.useState(
    candidates_tabs_one[0].value
  );
  const [tabsTwoValue, setTabsTwoValue] = React.useState(
    candidates_tabs_two[0].value
  );
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);

  useEffect(() => {
    const currentTab = candidates_tabs_two.find(
      (tab) => tab.value === tabsTwoValue
    );
    const tabLabelWithoutNumbersOrParentheses = currentTab.label.replace(
      /[0-9]|\(.*?\)/g,
      ""
    );

    setBreadcrumbsData((breadcrumbs) => [
      ...breadcrumbs.slice(0, 2),
      { name: tabLabelWithoutNumbersOrParentheses.trim() },
    ]);
  }, [tabsTwoValue, setBreadcrumbsData]);

  return (
    <Box
      sx={{
        width: "100%",
        background: "#fff",
      }}
    >
      {tabsTwoValue === "testtaken" ? (
        <TestTakenSearchHeader />
      ) : (
        <SearchHeader />
      )}
      {/* tabs */}
      <TabsList
        handleTabChange={setTabsTwoValue}
        value={tabsTwoValue}
        tabs={candidates_tabs_two}
      />

      <Box sx={{ mb: "16px" }}>
        {tabsTwoValue === "testtaken" && <TestTaken />}
        {tabsTwoValue === "passed" && <Passed />}
        {tabsTwoValue === "shortlisted" && <Shortlisted />}
        {tabsTwoValue === "reviewed" && <Reviewed />}
        {tabsTwoValue === "invited" && <Invited />}
      </Box>
    </Box>
  );
};

export default Candidates;

import PropTypes from "prop-types";
// @mui
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { NAV } from "../config-global";
import useResponsive from "../hooks/useResponsive";
import Iconify from "../components/iconify/Iconify";
import { useContext } from "react";
import { navContext } from "../context/navContext";

NavToggleButton.propTypes = {
  sx: PropTypes.object,
};

export default function NavToggleButton({ sx, ...other }) {
  const theme = useTheme();
  const { isNavMini, setIsNavMini } = useContext(navContext);
  const isDesktop = useResponsive("up", "md");

  if (!isDesktop) {
    return null;
  }

  return (
    <IconButton
      sx={{
        p: 0.5,
        top: 13,
        position: "fixed",
        left: NAV.W_DASHBOARD_MINI - 18,
        border: "1px solid",
        borderRadius: "4px",
        borderColor: theme.palette.secondary.main,
        zIndex: theme.zIndex.appBar + 1,
        "&:hover": {
          bgcolor: "background.default",
        },
        ...sx,
      }}
      onClick={() => setIsNavMini(!isNavMini)}
    >
      <Iconify
        icon="solar:arrow-right-linear"
        width={"16px"}
        color={"secondary.main"}
      />
    </IconButton>
  );
}

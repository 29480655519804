import React, { useContext, useEffect, useState } from "react";
import DrawerSlide from "../common/drawer/DrawerSlide";

import { navContext } from "../../context/navContext";
import DrawerHeader from "../common/drawer/DrawerHeader";
import { Box, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CandidateSnapshots from "./CandidateSnapshots";
import TabsSwitched from "./TabsSwitched";

const useStyles = makeStyles((theme) => ({
  tabHeader: {
    borderBottom: "1px solid #E8E9EE",
    marginBottom: "16px",
    "& button[aria-selected='true']": {
      color: theme.palette.green.secondaryGreen,
      fontWeight: 600,
    },
    "& .MuiTabs-indicator": {
      background: theme.palette.green.secondaryGreen,
    },
  },
  tableCard: {
    borderRadius: "4px",
    padding: "16px",
    background: theme.palette.background.default,
    width: "100%",
  },
}));
const CheatingDetected = ({ open, setOpen, currentTab = 0, drawerHeading }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(currentTab);
  const classes = useStyles();
  const theme = useTheme();

  const tabList = [
    "Candidate Snapshots(4)",
    "Tabs switched(4)",
    "Plagiarism (50%)",
  ];

  const handleChange = (event, newValue) => {
    setCurrentTabIndex(newValue);
  };

  useEffect(() => {
    setCurrentTabIndex(currentTab);
  }, [currentTab]);
  return (
    <DrawerSlide toggleDrawer={open} setToggleDrawer={setOpen}>
      <DrawerHeader headingText={drawerHeading} setToggleDrawer={setOpen} />
      <Stack sx={{ padding: " 8px 16px 16px " }} alignSelf={"stretch"}>
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          gap={"24px"}
          sx={{ padding: "0" }}
          //   alignSelf={"stretch"}
        >
          <Box alignSelf={"stretch"}>
            <Tabs
              className={classes.tabHeader}
              value={currentTabIndex}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabList.map((item) => (
                <Tab
                  label={item}
                  key={item}
                  sx={{
                    color: theme.palette.gray.main,
                    lineHeight: "20px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontWeight: 400,
                    padding: "10px 16px",
                  }}
                />
              ))}
            </Tabs>
            {/* <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "4px",
                height: "calc(100% - 64px)",
              }}
            >
              <Typography>Bharat</Typography>
            </Box> */}
            {currentTabIndex === 0 && <CandidateSnapshots />}
            {currentTabIndex === 1 && <TabsSwitched />}
          </Box>
        </Stack>
      </Stack>
      <Stack sx={{ padding: "8px 16px 16px" }}></Stack>
    </DrawerSlide>
  );
};

export default CheatingDetected;

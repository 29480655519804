import React, { useState } from "react";
import {
  Box,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CommonModal from "../modal/CommonModal";
import { useMessage } from "../../components/snackbar/snackbar";
import { AuthContext } from "../../context/authContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    background: "#fff",
    padding: "16px",
    border: "1px solid #E8E9EE",
    borderRadius: "12px",
  },
  desktop: {
    display: "flex",
    background: "#fff",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column", // Default value for base
    gap: "20px", // Default value for base
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: "0",
      alignItems: "center ",
    },
  },
  iconBox: {
    padding: "4px 6px",
    border: "1px solid #D3D9E2",
    borderRadius: 4,
    width: 36,
    height: 32,
  },
  avatarBox: {
    // display: "flex",
    alignItems: "center",
    gap: 6,
  },
  infoBox: {
    alignItems: "center",
    gap: "8px",
  },
  statusBox: {
    background: "#FFF0E7",
    padding: "4px 8px",
    color: "#FF6812",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    borderRadius: "0.25rem",
  },
  subtitle: {
    color: "#8591A2",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
  },
  textField: {
    border: "2px solid #E8E9EE",
    borderRadius: "4px",
  },
  placeholder: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#363E45",
  },
}));
function AnalyticsHeader() {
  const classes = useStyles();
  const [openMemberModal, setOpenMemberModal] = useState(false);
  const message = useMessage();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { authUser } = React.useContext(AuthContext);

  const handleCloseAddOrEdit = () => {
    setOpenMemberModal(false);
  };

  const handlePingClick = () => {
    handleCloseAddOrEdit();
    setOpenSnackbar(true);
    message("Message sent successfully !!", "success");
  };

  const getSubtitle = (text, props) => {
    return (
      <Typography className={classes.subtitle} sx={{ ...props }}>
        {text}
      </Typography>
    );
  };
  const invitedCount = 10;
  const attemptedCount = 128;
  const shortlistedCount = 10;

  return (
    <>
      <Box className={classes.root} sx={{}}>
        {/* Desktop Screen */}
        <Box className={classes.desktop}>
          {/* first part */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "28px",
                  width: "28px",
                  borderRadius: "34px",
                  backgroundColor: "#FF6812",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "-9px",
                }}
              >
                <img src="../assets/published.svg" alt="publish" />
              </Box>
              <Box
                sx={{
                  height: "24px",
                  width: "77px",
                  borderRadius: "24px",
                  backgroundColor: "#FFF0E7",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                  Published
                </Typography>
              </Box>
            </Box>

            <Divider
              sx={{ display: { base: "none", md: "block" } }}
              orientation="vertical"
              flexItem
            />

            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#FF6812",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                cursor: "pointer",
              }}
              onClick={() => setOpenMemberModal(!openMemberModal)}
            >
              <Tooltip title="Ping Recruiter" arrow placement="bottom">
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  {" "}
                  {/* Ensures alignment */}
                  <img src="../assets/Vector-5.svg" alt="bellIcon" />
                  John Deo
                </Box>
              </Tooltip>
            </Typography>
            <Divider
              sx={{ display: { base: "none", md: "block" } }}
              orientation="vertical"
              flexItem
            />
            {authUser?.role === "recruiter" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    padding: "0",
                  }}
                >
                  <img src="/assets/remark.svg" alt="remark" />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    35/100
                  </Typography>
                </Box>
                <Divider
                  sx={{ display: { base: "none", md: "block" } }}
                  orientation="vertical"
                  flexItem
                />
              </>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: { base: "column", md: "row" },
                gap: "16px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <Tooltip title="Test Preview" arrow placement="bottom">
                  <img src="../assets/Visit.svg" alt="Javascript Loops Test" />
                </Tooltip>
                Javascript Loops Test
              </Typography>
            </Box>
          </Box>
          {/* </Box> */}
          {/* second part */}
          <Box
            className={classes.infoBox}
            sx={{
              display: { base: "none", md: "flex" },
              gap: { base: "8px", md: "16px" },
            }}
          >
            <Stack
              direction="row"
              // divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              {/* <Box className={classes.avatarBox}>
                <Typography
                  sx={{
                    color: "#121111",
                    fontWeight: 700,
                    fontSize: 24,
                    lineHeight: "32px",
                  }}
                >
                  128
                </Typography>
                {getSubtitle("Attempted", { fontSize: "12px" })}
              </Box> */}
              <Box
                sx={{ paddingRight: "12px", borderRight: "1px solid #E8E9EE" }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                  {invitedCount || 0}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "400", color: "#535A5F" }}
                >
                  Invited
                </Typography>
              </Box>
              <Box
                sx={{ paddingRight: "12px", borderRight: "1px solid #E8E9EE" }}
              >
                <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                  {attemptedCount || 0}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "400", color: "#535A5F" }}
                >
                  Attempted
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                  {shortlistedCount || 0}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "400", color: "#535A5F" }}
                >
                  Shortlisted
                </Typography>
              </Box>
              {authUser?.role === "recruiter" && (
                <>
                  <Divider
                    sx={{ display: { base: "none", md: "block" } }}
                    orientation="vertical"
                    flexItem
                  />
                  <Box>
                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                      2
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#535A5F",
                      }}
                    >
                      Hired
                    </Typography>
                  </Box>
                </>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
      {/* Tablet/Mobile Screen */}
      <Box
        className={classes.infoBox}
        sx={{
          mt: "16px",
          display: { base: "flex", md: "none" },
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Box className={classes.avatarBox}>
            <Typography
              sx={{
                color: "#121111",
                fontWeight: 700,
                fontSize: 24,
                lineHeight: "32px",
              }}
            >
              400
            </Typography>
            {getSubtitle("Invites", { fontSize: "12px" })}
          </Box>

          <Box className={classes.avatarBox}>
            <Typography
              sx={{
                color: "#121111",
                fontWeight: 700,
                fontSize: 24,
                lineHeight: "32px",
              }}
            >
              128
            </Typography>
            {getSubtitle("Attempted", { fontSize: "12px" })}
          </Box>

          <Box
            className={classes.avatarBox}
            sx={{
              borderRight: "none",
              // display: { base: "none", md: "flex" },
            }}
          >
            <Typography
              sx={{
                color: "#121111",
                fontWeight: 700,
                fontSize: 24,
                lineHeight: "32px",
              }}
            >
              10
            </Typography>
            {getSubtitle("Shortlisted", { fontSize: "12px" })}
          </Box>
        </Stack>
      </Box>

      <CommonModal
        icon={"/assets/icons/bellRinging.svg"}
        handleClose={handleCloseAddOrEdit}
        open={openMemberModal}
        title={"Ping Recruiter"}
        sendButtonName={"Ping"}
        onSubmit={handlePingClick}
      >
        <Stack gap={"4px"}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "14px",
              lineheight: "20px",
              color: "#71777B",
            }}
          >
            Enter Message
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={6}
            placeholder="Enter your message"
            className={classes.textField}
            InputProps={{
              className: classes.placeholder, // Apply placeholder styles
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: "8px 12px 8px 12px",
                "& fieldset": {
                  borderColor: "#E8E9EE",
                  borderWidth: "2px",
                  borderRadius: "4px",
                },
              },
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#363E45",
                  opacity: 1,
                },
              },
            }}
          />
        </Stack>
      </CommonModal>
    </>
  );
}

export default AnalyticsHeader;

import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import CheatingCheckbox from "./CheatingCheckbox";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
const images = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath: "/assets/person/person.png",
    title: "09:00:10 IST",
  },
  {
    label: "Bird",
    imgPath: "/assets/person/person1.png",
    title: "09:00:10 IST",
  },
  {
    label: "Bali, Indonesia",
    imgPath: "/assets/person/person2.png",
    title: "09:00:10 IST",
  },
  {
    label: "Goč, Serbia",
    imgPath: "/assets/person/person3.png",
    title: "09:00:10 IST",
  },
];

const CandidateSnapshots = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const [page, setPage] = React.useState(1);
  const count = 4;
  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
    setActiveStep((prevActiveStep) =>
      prevActiveStep - 1 < 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, count));
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 >= maxSteps ? 0 : prevActiveStep + 1
    );
  };

  return (
    <Stack direction={"column"} alignItems={"flex-start"} gap={"16px"}>
      <CheatingCheckbox />
      <Stack direction={"column"} alignItems={"center"} gap={"8px"}>
        {images.map((step, index) =>
          index === activeStep ? (
            <Box
              key={step.label}
              component="img"
              sx={{
                height: { base: "303px", xs: "303px", md: "400px" },
                borderRadius: "4px",
                width: "100%",
              }}
              src={step.imgPath}
              alt={step.label}
            />
          ) : null
        )}

        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            disabled={page === 1}
            onClick={handlePrevPage}
            sx={{
              padding: "6px",
              borderRadius: "4px",
              border: "1px solid #D3D9E2",
              minWidth: "28px",
              boxSizing: "border-box",
              fontSize: "14px",
            }}
          >
            <KeyboardArrowLeft color="secondary" fontSize="1rem" />
          </Button>
          <Typography
            variant="body1"
            sx={{
              padding: "4px",
              fontFamily: "Roboto",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#71777B",
            }}
          >{`${page} of ${count}`}</Typography>
          <Button
            disabled={page === count}
            variant="outlined"
            onClick={handleNextPage}
            color="inherit"
            sx={{
              padding: "6px",
              borderRadius: "4px",
              border: "1px solid #D3D9E2",
              minWidth: "28px",
              boxSizing: "border-box",
              fontSize: "14px",
            }}
          >
            <KeyboardArrowRight color="secondary" fontSize="1rem" />
          </Button>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"16px"}
        >
          {images.map((image, index) => (
            <Stack
              direction={"column"}
              alignItems={"center"}
              gap={"8px"}
              alignSelf={"stretch"}
              key={index}
            >
              <Box
                component={"img"}
                sx={{
                  boxSizing: "border-box",
                  border: "1px solid #E8E9EE",
                  borderRadius: "4px",
                  height: { base: "64px", xs: "64px", md: "120px" },
                  width: { base: "60px", xs: "60px", md: "160px" },
                  boxShadow:
                    index === activeStep ? "0px 0px 0px 2px #0077ff" : "none", // Highlight active step
                }}
                src={image.imgPath}
                alt={image.alt}
              />
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                {image.title}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CandidateSnapshots;

import React, { useState, useEffect } from "react";
import FunnelGraph from "funnel-graph-js"; // Import the funnel graph library

const FunnelChart = React.memo(() => {
  const [chartRendered, setChartRendered] = useState(false);

  useEffect(() => {
    if (!chartRendered) {
      const data = {
        labels: ["Invited", "Test attempted", "Shortlisted"],
        subLabels: ["0% drop w.r.t invited", "100% drop w.r.t attempted"],
        colors: [["#D5F9F1"], ["#D5F9F1"]],
        values: [[100], [100], [50]],
      };

      const graph = new FunnelGraph({
        container: ".funnel-chart",
        gradientDirection: "horizontal",
        data: data,
        direction: "Horizontal",
        width: 1100, // Adjust width based on screen size
        height: 126,
        subLabelValue: "values",
      });

      graph.draw();
      setChartRendered(true);
    }
  }, [chartRendered]);

  return <div className="funnel-chart"></div>;
});

export default FunnelChart;

import { Box } from "@mui/material";
import React from "react";

const DifficultyIcon = ({ color = "#439D62" }) => {
  return (
    <Box sx={{ width: "16px", height: "16px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="13"
        viewBox="0 0 10 13"
        fill="none"
      >
        <path
          d="M8.01693 4.35532C7.96771 4.30123 7.90669 4.25921 7.83861 4.2325C7.77053 4.2058 7.69722 4.19513 7.62435 4.20132C7.55129 4.20821 7.4809 4.23227 7.4189 4.27153C7.3569 4.31079 7.30506 4.36414 7.26758 4.42723C6.57292 5.59422 6.1543 5.88622 5.92578 5.92593C5.82042 5.9404 5.71331 5.91785 5.62272 5.86212C6.69727 3.73612 5.42025 1.09064 4.66539 0.335757C4.60008 0.270536 4.5169 0.226134 4.42637 0.208159C4.33584 0.190184 4.24201 0.199443 4.15673 0.234767C4.07146 0.270091 3.99857 0.329894 3.94727 0.406622C3.89596 0.483351 3.86855 0.573563 3.86849 0.665864C3.86849 2.43018 2.88152 3.43962 2.08854 4.25053L2.00098 4.34038C1.07764 5.33733 0.555557 6.6405 0.535156 7.9992C0.535156 9.18387 1.00576 10.32 1.84345 11.1577C2.68114 11.9954 3.81729 12.466 5.00196 12.466C6.18663 12.466 7.32277 11.9954 8.16046 11.1577C8.99815 10.32 9.46876 9.18387 9.46876 7.9992C9.35749 6.66733 8.85206 5.39878 8.01693 4.35532Z"
          fill={color}
        />
      </svg>
    </Box>
  );
};

export default DifficultyIcon;

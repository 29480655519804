import PropTypes from "prop-types";
// @mui
import { Box } from "@mui/material";
import useResponsive from "../hooks/useResponsive";
import { HEADER, NAV } from "../config-global";
import theme from "../theme/theme";
import { navContext } from "../context/navContext";
import { useContext } from "react";
// hooks

const SPACING = 15;

Main.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

export default function Main({ children, sx, ...other }) {
  const isNavHorizontal = false;

  const { isNavMini } = useContext(navContext);

  const isDesktop = useResponsive("up", "lg");
  const isTablet = useResponsive("", "md");

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          pt: `${HEADER.H_MOBILE + SPACING}px`,
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          ...(isDesktop && {
            px: 2,
            pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
            pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: "100vh",
        backgroundColor: theme.palette.background.gray,
        pt: `${HEADER.H_MOBILE + SPACING}px`,
        px: 1.5,
        width: `100%`,
        ...(isDesktop && {
          // pt: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          pt: `48px`,
          pb: `${SPACING}px`,
          width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
          }),
        }),
        ...(isTablet && {
          pt: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          pb: `${SPACING}px`,
          minWidth: `calc(100% - ${NAV.W_DASHBOARD}px)`,
          ...(isNavMini && {
            minWidth: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

import * as React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { candidate_shortlisted_table_rows } from "../../utils/data";
import CustomTable from "../common/customTable/CustomTable";
import SortingIcon from "../icons/SortingIcon";
import { useNavigate } from "react-router-dom";

const columnsWidth = ["60px", "240px", "90px", "240px", "240px", "240px"];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

const tableHeaderTitles = [
  {
    title: "Sr. no",
    cellStyleProps: {
      borderRight: "1px solid #E8E9EE",
      width: columnsWidth[0],
    },
  },
  {
    isSortCol: true,
    colId: "candidate",
    title: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
        >
          Candidate
        </Typography>
        <SortingIcon />
      </Box>
    ),
    cellStyleProps: {
      width: columnsWidth[1],
    },
  },
  {
    title: "Channel",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Interviewer",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    title: "Job",
    cellStyleProps: {
      width: columnsWidth[4],
    },
  },
  {
    title: "Round",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
];
const tableHeaderCells = () => {
  return tableHeaderTitles.map((item) => {
    return {
      isSortCol: item?.isSortCol ? item.isSortCol : false,
      colId: item?.colId ? item.colId : "",
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};

const statusTagColor = (id) => {
  if (id === 0) {
    return { color: "#FF6812", bg: "#FFF0E7" };
  }
  if (id === 1) {
    return { color: "#285837", bg: "#E2F4C5" };
  }
  if (id === 2) {
    return { color: "#004E3E", bg: "#D5F9F1" };
  }
  if (id === 3) {
    return { color: "#733430", bg: "#FADCDA" };
  }
  if (id === 4) {
    return { color: "#003285", bg: "#EEF7FF" };
  }
  if (id === 5) {
    return { color: "#9E6F21", bg: "#FAFFAF" };
  }
};

export default function Shortlisted({ data }) {
  const navigate = useNavigate();
  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          width: columnsWidth[0],
          maxWidth: columnsWidth[0],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.rowId}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[1],
          maxWidth: columnsWidth[1],
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src={
                row?.candidate?.avtar ? row.candidate.avtar : "/assets/user.png"
              }
              alt="user"
              style={{ height: "20px", width: "20px" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
                cursor: "pointer",
              }}
              onClick={() => navigate("/talent_pool/cadidateDetails")}
            >
              {row?.candidateId?.name}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[2],
          maxWidth: columnsWidth[2],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.channelId?.name}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[3],
          maxWidth: columnsWidth[3],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.interviewerId?.name}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[4],
          maxWidth: columnsWidth[4],
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row?.assessmentId?.jobRoleId?.jobRole}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          width: columnsWidth[5],
          maxWidth: columnsWidth[5],
          ...cellStyleProps,
        },
        element: getStatus(row?.status),
      },
    ];
  };

  const tableBodyContent = () => {
    return data?.map((row, idx) => {
      const cellStyleProps = {
        borderBottom: "1px solid #E8E9EE",
        padding: "10px 14px",
        whiteSpace: "nowrap",
      };
      return {
        sortable: {
          score: parseInt(row.score),
          candidate: row.data?.candidateId?.name,
        },
        rowId: idx + 1,
        rowElement: tableBodyRowCell(
          { ...row, rowId: idx + 1 },
          cellStyleProps
        ),
      };
    });
  };

  const getStatus = (status) => {
    return (
      <Box
        sx={{
          display: "inline-block",
          background: statusTagColor(status?.id)?.bg,
          padding: "4px 12px",
          color: statusTagColor(status?.id)?.color,
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "16px",
          fontFamily: "Roboto",
          borderRadius: "0.25rem",
          whiteSpace: "nowrap",
        }}
      >
        {status?.tag}
      </Box>
    );
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        tableHeaderCells={tableHeaderCells()}
        tableBodyContent={tableBodyContent()}
        tableData={candidate_shortlisted_table_rows}
        sortRows={() => {}}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
      />
    </Box>
  );
}

import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    background: "#fff",
    padding: "18px 16px",
    border: "1px solid #E8E9EE",
    borderRadius: "12px",
  },
  desktop: {
    display: "flex",
    background: "#fff",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column", // Default value for base
    gap: "20px", // Default value for base
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      gap: "0",
      alignItems: "center ",
    },
  },
  iconBox: {
    padding: "4px 6px",
    border: "1px solid #D3D9E2",
    borderRadius: 4,
    width: 36,
    height: 32,
  },
  avatarBox: {
    // display: "flex",
    alignItems: "center",
    gap: 6,
  },
  infoBox: {
    alignItems: "center",
    gap: "8px",
  },
  statusBox: {
    background: "#FFF0E7",
    padding: "4px 8px",
    color: "#FF6812",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "20px",
    borderRadius: "0.25rem",
  },
  subtitle: {
    color: "#8591A2",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
  },
}));

function Header() {
  const classes = useStyles();

  const getSubtitle = (text, props) => (
    <Typography className={classes.subtitle} sx={{ ...props }}>
      {text}
    </Typography>
  );

  return (
    <Box className={classes.root} sx={{}}>
      {/* Desktop Screen */}
      <Box className={classes.desktop}>
        {/* first part */}
        <Box
          className={classes.infoBox}
          sx={{ display: "flex", gap: { base: "8px", md: "16px" } }}
        >
          <Box className={classes.iconBox}>
            <img src="/assets/code_icon.svg" alt="Code Icon" />
          </Box>
          <Typography
            sx={{
              color: "#313131",
              fontSize: 18,
              fontWeight: 700,
              lineHeight: "24px",
              margin: "0px 12px",
            }}
          >
            Javascript Loops Test
          </Typography>
          <Box className={classes.statusBox}>Published</Box>
        </Box>
        {/* second part */}
        <Box
          className={classes.infoBox}
          sx={{ display: "flex", gap: { base: "8px", md: "16px" } }}
        >
          <Box
            className={classes.avatarBox}
            sx={{
              borderRightWidth: { base: "0px", md: "2px" },
              paddingRight: "10px",
              display: "flex",
              borderRight: "2px solid #D3D9E2",
            }}
          >
            <img src="/assets/avatar_icon.svg" alt="Avatar Icon" />
            {getSubtitle("Sumith Krishn")}
          </Box>
          <Box
            className={classes.avatarBox}
            sx={{
              display: { base: "none", md: "flex" },
              paddingX: "10px",
              borderRight: "2px solid #D3D9E2",
            }}
          >
            {getSubtitle("Attempted")}
            <Typography
              sx={{
                color: "#121111",
                fontWeight: 500,
                fontSize: 18,
                lineHeight: "27px",
              }}
            >
              128
            </Typography>
          </Box>
          <Box
            className={classes.avatarBox}
            sx={{ borderRight: "none", display: { base: "none", md: "flex" } }}
          >
            {getSubtitle("Shortlisted", { paddingLeft: "10px" })}
            <Typography
              sx={{
                color: "#121111",
                fontWeight: 500,
                fontSize: 18,
                lineHeight: "27px",
              }}
            >
              10
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* Tablet/Mobile Screen */}
      <Box
        className={classes.infoBox}
        sx={{
          mt: { base: "12px", sm: "16px" },
          display: { base: "flex", md: "none" },
        }}
      >
        <Box
          className={classes.avatarBox}
          sx={{
            paddingRight: "10px",
            display: "flex",
            borderRight: "2px solid #D3D9E2",
          }}
        >
          {getSubtitle("Attempted")}

          <Typography
            sx={{
              color: "#121111",
              fontWeight: 500,
              fontSize: 18,
              lineHeight: "27px",
            }}
          >
            128
          </Typography>
        </Box>
        <Box
          className={classes.avatarBox}
          sx={{
            borderRight: "none",
            paddingLeft: "10px",
            display: "flex",
          }}
        >
          {getSubtitle(" Shortlisted")}

          <Typography
            sx={{
              color: "#121111",
              fontWeight: 500,
              fontSize: 18,
              lineHeight: "27px",
            }}
          >
            10
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;

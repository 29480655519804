import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import useResponsive from "../../hooks/useResponsive";
import ChartCard from "../dashboardPage/ChartCard";
import theme from "../../theme/theme";
import { TbTableExport } from "react-icons/tb";
import FunnelChart from "./FunnelChart";

function TestAnalytics() {
  const isLargeScreen = useResponsive("up", "lg");

  const options = {
    chart: {
      height: 330,
      type: "bar",
      stacked: !0,
      toolbar: {
        show: !1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        horizontal: !1,
        columnWidth: "25%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box">' +
          "<span>" +
          w.globals.labels[dataPointIndex] +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
    series: [
      {
        name: "Departments",
        data: [0.5, 3, 6, 6, 2, 4, 6, 1, 0.5, 6, 2, 1],
      },
    ],
    xaxis: {
      categories: [
        "0",
        "1-9",
        "10-19",
        "20-29",
        "30-39",
        "40-49",
        "50-59",
        "60-69",
        "70-79",
        "80-89",
        "90-99",
        "100",
      ],
      labels: {
        formatter: function (value) {
          if (value.length > 5) {
            return value.slice(0, 5) + "..."; // Display first five characters followed by ellipsis for long names
          }
          return value;
        },
      },
    },
    colors: ["#00C49A"],
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  };
  const menuItemsCandidate = [
    {
      value: "this year",
      label: "This Year",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
  var questionBreakdownOptions = {
    series: [250, 150, 300],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 320,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#DA38FA", "#328DF6", "#8962F3"],
      dataLabels: {
        enabled: false,
      },
      labels: ["MCQ", "SQL", "Programming"],
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
                label: "Questions",
                color: "#535A5F",
                fontSize: "12px",
                formatter: function (val) {
                  return 15;
                },
              },
            },
          },
        },
        tooltip: {
          fillSeriesColor: false,
        },
        legend: {
          width: isLargeScreen ? 200 : 150,
          show: true,
          labels: {
            colors: "#363E45",
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            radius: 2,
          },
          itemMargin: {
            vertical: 6,
          },
        },
      },
      legend: {
        width: isLargeScreen ? 150 : 120,
        show: true,
        labels: {
          colors: "#363E45",
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          radius: 2,
        },
        itemMargin: {
          vertical: 6,
        },
      },
    },
  };
  var questionsAnalysisOptions = {
    series: [44, 55, 41],
    options: {
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 320,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#439D62", "#FF9736", "#F94948"],
      dataLabels: {
        enabled: false,
      },
      labels: ["Easy", "Medium", "Hard"],
      plotOptions: {
        pie: {
          donut: {
            size: "75%",
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
                label: "Questions",
                color: "#535A5F",
                fontSize: "12px",
                formatter: function (val) {
                  return 15;
                },
              },
            },
          },
        },
      },
      tooltip: {
        fillSeriesColor: false,
      },
      legend: {
        width: isLargeScreen ? 150 : 120,
        show: true,
        labels: {
          colors: "#363E45",
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          radius: 2,
          horizontal: 12,
        },
        itemMargin: {
          vertical: 6,
        },
      },
    },
  };
  return (
    <Box>
      <Box
        sx={{
          display: { base: "flex" },
          flexDirection: "column",
          border: "1px solid",
          borderColor: theme.palette.primary.gray,
          marginTop: "16px",
        }}
      >
        <ChartCard
          title="Candidate Score Graph"
          subTitle="No. of Candidates who score greater than or equal to median score (55%)"
          menuItems={menuItemsCandidate}
        >
          <Box
            sx={{
              width: { base: "100%", sm: "50%" },
              gap: "12px",
              display: "flex",
              flexDirection: "row",
            }}
          ></Box>
        </ChartCard>
        <Box
          sx={{
            backgroundColor: "#fff",
            overflowY: "hidden",
            minHeight: "300px",
          }}
          className="bar-chart"
        >
          <Chart
            options={options}
            type="bar"
            series={options.series}
            width={isLargeScreen ? "100%" : "200%"}
            height="345px"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { base: "repeat(1,1fr)", sm: "repeat(2,1fr)" },
          gap: "16px",
          marginTop: "16px",
        }}
      >
        <Box
          sx={{
            display: { base: "flex" },
            flexDirection: "column",
            marginTop: "16px",
            border: "1px solid",
            borderColor: theme.palette.primary.gray,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "4px",
              borderBottom: "1px solid",
              borderColor: theme.palette.primary.gray,
              width: "100%",
              padding: "16px",
              display: "flex",
              flexDirection: { base: "column", lg: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: {
                  base: "row",
                  sm: "row",
                },
                alignItems: "center",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  width: { base: "100%", md: "100%" },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: theme.palette.primary.black,
                  }}
                >
                  Question Difficulty Analysis
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: theme.palette.text.gray300,
                  }}
                >
                  Difficulty wise distribution
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "24px",
                  width: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <TbTableExport
                  size={22}
                  color={theme.palette.secondary.main}
                  fontWeight={400}
                />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ backgroundColor: "#fff" }}>
            <Chart
              options={questionsAnalysisOptions.options}
              series={questionsAnalysisOptions.series}
              type="donut"
              width={isLargeScreen ? "440px" : "320px"}
              height="300px"
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "16px 0 16px 0",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: { base: "flex" },
            flexDirection: "column",
            marginTop: "16px",
            border: "1px solid",
            borderColor: theme.palette.primary.gray,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "4px",
              borderBottom: "1px solid",
              borderColor: theme.palette.primary.gray,
              width: "100%",
              padding: "16px",
              display: "flex",
              flexDirection: { base: "column", lg: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: {
                  base: "row",
                  sm: "row",
                },
                alignItems: "center",
                justifyContent: "space-between",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  width: { base: "100%", md: "100%" },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: theme.palette.primary.black,
                  }}
                >
                  Question Type Breakdown
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: theme.palette.text.gray300,
                  }}
                >
                  Question Type distribution
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "24px",
                  width: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                <TbTableExport
                  size={22}
                  color={theme.palette.secondary.main}
                  fontWeight={400}
                />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ backgroundColor: "#fff" }}>
            <Chart
              options={questionBreakdownOptions.options}
              series={questionBreakdownOptions.series}
              type="donut"
              width={isLargeScreen ? "440px" : "320px"}
              height="300px"
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "16px 0 16px 0",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { base: "flex" },
          flexDirection: "column",
          border: "1px solid",
          borderColor: theme.palette.primary.gray,
          marginTop: "16px",
        }}
      >
        <ChartCard
          title="Candidate Hiring Funnel"
          subTitle="50% Attempted"
          menuItems={menuItemsCandidate}
          isDefaultColor={false}
        >
          <Box
            sx={{
              width: { base: "100%", sm: "50%" },
              gap: "12px",
              display: "flex",
              flexDirection: "row",
            }}
          ></Box>
        </ChartCard>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            padding: "16px 16px 40px 16px",
            overflowY: "hidden",
          }}
          id="test-analytics-overview-root"
        >
          <FunnelChart />
        </Box>
      </Box>
    </Box>
  );
}

export default TestAnalytics;

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import React, { useState } from "react";
import SearchBar from "../common/SearchBar";
import theme from "../../theme/theme";
import { AuthContext } from "../../context/authContext";

const TestTakenSearchHeader = () => {
  const [channel, setChannel] = useState("All");
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const { authUser } = React.useContext(AuthContext);

  const handleChange = (event) => {
    setChannel(event.target.value);
  };

  const handleClick = () => {
    // Add your export functionality here

    setDownloadSuccess(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDownloadSuccess(false);
  };

  return (
    <Box>
      {/* for desktop */}
      <Grid
        container
        spacing={2}
        sx={{ mt: "0.1rem", display: { base: "none", md: "flex" } }}
      >
        <Grid item base={6} lg={7} xl={8}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#010101",
                fontFamily: "Nunito",
              }}
            >
              Candidates (103)
            </Typography>
            <SearchBar placeholder="Search Candidate" width="296px" />
          </Box>
        </Grid>
        <Grid item base={6} lg={5} xl={4}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <FormControl
              fullWidth
              sx={{
                backgroundColor: "#fff",
                height: "38px",
                ...(authUser.role === "super admin" && {
                  marginLeft: "150px",
                }),
              }}
            >
              <InputLabel
                sx={{
                  letterSpacing: "0.5px",
                }}
              >
                Status
              </InputLabel>
              <Select
                sx={{
                  height: "38px",
                  width: "100%",
                }}
                value={channel}
                label="chanel"
                onChange={handleChange}
                inputProps={{ className: "select-padding" }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Passed">Passed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
                <MenuItem value="Shortlisted">Shortlisted</MenuItem>
                <MenuItem value="Reviewed">Reviewed</MenuItem>
              </Select>
            </FormControl>

            {authUser.role === "recruiter" && (
              <Button
                // onClick={() => setToggleDrawer((prev) => !prev)}
                variant="contained"
                sx={{
                  minWidth: "137px",
                  color: theme.palette.background.default,
                  padding: "10px 10px 10px 12px ",
                  height: "36px",
                  width: "100%",
                  lineHeight: "20px",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  fontWeight: "400",
                  borderRadius: "0.25rem",
                }}
                color="orangeButton"
                size="large"
                startIcon={<Box component={"img"} src="/assets/cutoff.svg" />}
              >
                Update Cutoff
              </Button>
            )}

            <Button
              variant="contained"
              onClick={handleClick}
              sx={{
                backgroundColor: theme.palette.green.secondaryGreen,
                minWidth: "91px",
                color: theme.palette.background.default,
                padding: "10px 10px 10px 12px ",
                height: "36px",
                width: "91px",
                lineHeight: "20px",
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: "400",
                borderRadius: "0.25rem",
              }}
              color="greenButton"
              size="large"
              startIcon={
                <Box component={"img"} src="/assets/export_icon.svg" />
              }
            >
              Export
            </Button>

            <Snackbar
              open={downloadSuccess}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity="success"
              >
                Downloaded successfully !
              </MuiAlert>
            </Snackbar>
          </Box>
        </Grid>
      </Grid>
      {/* for tablet/mobile */}
      <Box
        sx={{
          my: "1rem",
          ml: "0.25rem",
          display: { base: "block", md: "none" },
        }}
      >
        <Typography
          sx={{
            whiteSpace: "nowrap",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#010101",
            fontFamily: "Nunito",
          }}
        >
          Candidates
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ display: { base: "flex", md: "none" } }}
      >
        <Grid item base={12}>
          <SearchBar placeholder="Search" width="100%" />
        </Grid>
        <Grid item base={12} sm={4}>
          <FormControl
            fullWidth
            sx={{
              backgroundColor: "#fff",
              height: "40px",
              // width: "100%",
            }}
          >
            <InputLabel
              sx={{
                letterSpacing: "0.5px",
              }}
            >
              Status
            </InputLabel>
            <Select
              sx={{
                "& .select-padding": {
                  padding: "10px 8px", // Adjust padding as needed
                },
                height: "40px",
              }}
              value={channel}
              label="chanel"
              onChange={handleChange}
              inputProps={{ className: "select-padding" }}
            >
              <MenuItem value="All">All</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item base={12} sm={8}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              // onClick={() => setToggleDrawer((prev) => !prev)}
              variant="contained"
              sx={{
                minWidth: "137px",
                color: theme.palette.background.default,
                padding: "10px 10px 10px 12px ",
                height: "36px",
                width: "100%",
                lineHeight: "20px",
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: "400",
                borderRadius: "0.25rem",
              }}
              color="orangeButton"
              size="large"
              startIcon={<Box component={"img"} src="/assets/cutoff.svg" />}
            >
              Update Cutoff
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.green.secondaryGreen,
                minWidth: "91px",
                color: theme.palette.background.default,
                padding: "10px 10px 10px 12px ",
                height: "36px",
                width: "100%",
                lineHeight: "20px",
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: "400",
                borderRadius: "0.25rem",
              }}
              color="greenButton"
              size="large"
              startIcon={
                <Box component={"img"} src="/assets/export_icon.svg" />
              }
            >
              Export
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TestTakenSearchHeader;

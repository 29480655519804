import React, { useContext } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { HEADER, NAV } from "../config-global";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useResponsive from "../hooks/useResponsive";
import CustomBreadcrumbs from "../components/custom-breadcrumbs";
import { navContext } from "../context/navContext";
import { breadcrumbsContext } from "../context/breadcrumbsContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  const { isNavMini } = useContext(navContext);
  const { breadcrumbsData } = useContext(breadcrumbsContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { authUser } = useContext(AuthContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogout = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    localStorage.removeItem("authUser");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("accessToken");
    window.location.reload();
  };

  const dashboardHeader = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <CustomBreadcrumbs links={breadcrumbsData} />
      <Box gap={"8px"} display={"flex"} alignItems={"center"}>
        <Tooltip title="Messages" arrow placement="top">
          <Box
            component="img"
            sx={{
              width: 24,
              height: 24,
            }}
            src="/assets/icons/fourDot.svg"
          />
        </Tooltip>
        <Tooltip title="Notifications" arrow placement="top">
          <Box
            component="img"
            sx={{
              width: 24,
              height: 24,
            }}
            src="/assets/icons/bell.svg"
          />
        </Tooltip>
        <Box
          sx={{
            margin: "0 8px",
            padding: "4px 8px",
            backgroundColor: "secondary.light",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              color: "secondary.main",
              textTransform: "capitalize",
            }}
          >
            {authUser
              ? authUser.role.charAt(0).toUpperCase() + authUser.role.slice(1)
              : "User"}
          </Typography>
        </Box>
        <Avatar
          sx={{ cursor: "pointer", width: "32px", height: "32px" }}
          onClick={handleClick}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleMenuItemClick("/account")}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>My Profile</ListItemText>
          </MenuItem>
          {authUser.role === "super admin" && (
            <MenuItem onClick={() => handleMenuItemClick("/settings")}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={() => handleLogout("/logout")}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: 1000,
        bgcolor: "background.default",
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${230}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { md: "16px" },
        }}
        variant="dense"
      >
        {dashboardHeader}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import { Grid } from "@mui/material";
import React from "react";
import ShortlistedCandidateCard from "../common/ShortlistedCandidateCard";
import { ScheduleData } from "../../utils/data";

const SortListedGrid = () => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="stretch"
      sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
    >
      {ScheduleData.map((elem, index) => (
        <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
          <ShortlistedCandidateCard data={elem} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SortListedGrid;

import React, { useState } from "react";
import {
  Box,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Alert,
  Snackbar,
} from "@mui/material";
import { candidates_reviewed_table_rows } from "../../utils/data";
import CustomTable from "../common/customTable/CustomTable";
import { Checkbox } from "@mui/material";
import SortingIcon from "../icons/SortingIcon";
import { useNavigate } from "react-router-dom";

const columnsWidth = [
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
  "60px",
];

const tableHeaderCellStyleProps = {
  color: "#363E45",
  lineHeight: "16px",
  fontSize: "12px",
  fontWeight: "500",
  fontFamily: "Roboto",
  padding: "8px 16px",
  height: "36px",
  whiteSpace: "nowrap",
};
const tableContentCellTextStyleProps = {
  textAlign: "left",
  fontSize: "14px",
  color: "#363E45",
  fontWeight: "400",
  lineHeight: "20px",
  fontFamily: "Roboto",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};
const tableBodyRowStyleProps = {
  height: "48px",
};

const tableHeaderTitles = [
  {
    title: "",
    cellStyleProps: {
      width: columnsWidth[0],
    },
  },
  {
    title: "Sr. no",
    cellStyleProps: {
      width: columnsWidth[0],
    },
  },
  {
    isSortCol: true,
    colId: "candidate",
    title: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
        >
          Candidate
        </Typography>
        <SortingIcon />
      </Box>
    ),
    cellStyleProps: {
      width: columnsWidth[1],
    },
  },
  {
    title: "Attempt %",
    cellStyleProps: {
      width: columnsWidth[2],
    },
  },
  {
    title: "Shortlisted at",
    cellStyleProps: {
      width: columnsWidth[3],
    },
  },
  {
    isSortCol: true,
    colId: "score",
    title: (
      <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: "500", fontFamily: "Roboto" }}
        >
          Score
        </Typography>
        <SortingIcon />
      </Box>
    ),
    cellStyleProps: {
      width: columnsWidth[4],
    },
  },
  {
    title: "Test Duration",
    cellStyleProps: {
      width: columnsWidth[5],
    },
  },

  {
    title: "",
    cellStyleProps: {
      width: columnsWidth[6],
    },
  },
];

const tableHeaderCells = () => {
  return tableHeaderTitles.map((item) => {
    return {
      isSortCol: item?.isSortCol ? item.isSortCol : false,
      colId: item?.colId ? item.colId : "",
      element: item.title,
      cellStyleProps: item.cellStyleProps,
      isSortable: item?.isSortable ? item.isSortable : false,
      cellId: item?.cellId ? item.cellId : "",
    };
  });
};

export default function Reviewed() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleViewDetails = () => {
    navigate("/talent_pool/cadidateDetails");
    handleMenuClose();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleGetLink = () => {
    const link =
      "https://staging.dev.theeliteqa.com/candidateresult/66694b7d69dfc4fef59e9df6";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setAlertMessage("Link copied to clipboard!");
        setOpen(true);
      })
      .catch(() => {
        setAlertMessage("Failed to copy the link.");
        setOpen(true);
      });
    handleMenuClose();
  };

  const tableBodyRowCell = (row, cellStyleProps) => {
    return [
      {
        cellStyleProps: {
          borderRight: "1px solid #E8E9EE",
          flex: "1",
          textAlign: "center",
          ...cellStyleProps,
        },
        element: (
          <Checkbox sx={{ padding: "0rem", width: "16px", height: "16px" }} />
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.srNo}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <img
              src="/assets/people.png"
              alt="user"
              style={{ height: "20px", width: "20px" }}
            />
            <Typography
              sx={{
                ...tableContentCellTextStyleProps,
                cursor: "pointer",
              }}
              onClick={() => navigate("/talent_pool/cadidateDetails")}
            >
              {row.candidate}
            </Typography>
          </Box>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          whiteSpace: "nowrap",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.attempt}%
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          whiteSpace: "nowrap",
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.shortlistedat}
          </Typography>
        ),
      },
      {
        cellStyleProps: {
          flex: "1",
          ...cellStyleProps,
        },
        element: (
          <Typography
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.score}
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: (
          <Typography
            variant="p"
            sx={{
              ...tableContentCellTextStyleProps,
            }}
          >
            {row.testduration}mins
          </Typography>
        ),
      },
      {
        cellStyleProps,
        element: (
          <IconButton onClick={(event) => handleMenuClick(event, row)}>
            <img src="/assets/three_dots_icon.svg" alt="three dots" />
          </IconButton>
        ),
      },
    ];
  };

  const tableBodyContent = () => {
    const cellStyleProps = {
      borderBottom: "1px solid #E8E9EE",
      padding: "10px 14px",
    };

    return candidates_reviewed_table_rows.map((row, idx) => {
      return {
        sortable: { score: parseInt(row.score), candidate: row.candidate },
        rowId: row.id,
        rowElement: tableBodyRowCell({ ...row, srNo: idx + 1 }, cellStyleProps),
      };
    });
  };

  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
      <CustomTable
        tableHeaderCells={tableHeaderCells()}
        tableBodyContent={tableBodyContent()}
        tableData={candidates_reviewed_table_rows}
        sortRows={() => {}}
        tableBodyRowStyleProps={tableBodyRowStyleProps}
        tableHeaderCellStyleProps={tableHeaderCellStyleProps}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleViewDetails}>
          <img
            style={{
              height: "22px",
              width: "22px",
              marginRight: "8px",
              color: "#000",
            }}
            src="/assets/eye copy.svg"
          />
          View Details
        </MenuItem>
        <MenuItem onClick={handleGetLink}>
          <img style={{ marginRight: "8px" }} src="/assets/arrow-up.svg" />
          Get Result Link
        </MenuItem>
      </Menu>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{
            width: "100%",
            bgcolor: "#00c49a",
            color: "white",
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

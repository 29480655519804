import { InputAdornment, TextField } from "@mui/material";
import { IoSearchSharp } from "react-icons/io5";
import PropTypes from "prop-types";

const SearchBar = ({ width = "300px", value, placeholder, onChange }) => {
  return (
    <TextField
      sx={{
        width: { base: "100%", md: width },
        borderRadius: "6px",
        "& fieldset": {
          border: "1px solid #bdbdbd",
        },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: "#bdbdbd",
          },
        },
        "& input": {
          padding: "7px 0px",
          borderRadius: "6px",
        },
      }}
      placeholder={placeholder ? placeholder : "Search..."}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IoSearchSharp />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
    />
  );
};
SearchBar.propTypes = {
  width: PropTypes.string,
};

export default SearchBar;

import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Router from "./routes";
import { useMemo, useState } from "react";
import { navContext } from "./context/navContext";
import "./index.css";
import { SnackbarProvider } from "notistack";
import AuthProvider from "./context/authProvider";

function App() {
  const [isNavMini, setIsNavMini] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const handleChangeDrawer = () => {
    setToggleDrawer(!toggleDrawer);
  };

  const obj = useMemo(
    () => ({
      isNavMini,
      setIsNavMini,
      toggleDrawer,
      setToggleDrawer,
      isVisible,
      setIsVisible,
    }),
    [isNavMini, toggleDrawer, isVisible]
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <navContext.Provider value={obj}>
        <AuthProvider>
          <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
              <Router />
            </SnackbarProvider>
          </BrowserRouter>
        </AuthProvider>
      </navContext.Provider>
    </LocalizationProvider>
  );
}

export default App;

import { Suspense, lazy } from "react";

const Loadable = (Component) => (props) =>
  (
    // <Suspense fallback={<LoadingScreen />}>
    <Suspense fallback={<h1>Loading...</h1>}>
      <Component {...props} />
    </Suspense>
  );

export const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
export const JobOpening = Loadable(lazy(() => import("../pages/JobOpening")));
export const Assessments = Loadable(
  lazy(() => import("../pages/assessments/Assessments"))
);
export const Library = Loadable(lazy(() => import("../pages/Library")));
export const Candidates = Loadable(lazy(() => import("../pages/Candidates")));
export const Interviews = Loadable(lazy(() => import("../pages/Interviews")));
export const TalentPool = Loadable(lazy(() => import("../pages/TalentPool")));
export const Settings = Loadable(lazy(() => import("../pages/Settings")));
export const Login = Loadable(lazy(() => import("../pages/auth/Login")));
export const Account = Loadable(lazy(() => import("../pages/Account")));
export const AccountPage = Loadable(lazy(() => import("../pages/AccountPage")));
export const ShortlistedCandidates = Loadable(
  lazy(() => import("../pages/ShortlistedCandidates"))
);
export const ViewQuestions = Loadable(
  lazy(() => import("../pages/ViewQuestions"))
);
export const TalentPoolCandidateDetails = Loadable(
  lazy(() => import("../pages/TalentPoolSection"))
);
export const Questions = Loadable(lazy(() => import("../pages/Questions")));
export const LogOut = Loadable(lazy(() => import("../pages/auth/LogOut")));

export const JobDetail = Loadable(lazy(() => import("../pages/JobDetail")));

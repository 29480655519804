import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react'
import Iconify from '../iconify/Iconify';
import styled from "styled-components";

const Divider = styled.div`
  width: 2px;
  height: 0px;
  border: 2px solid #8591a2;
  transform: rotate(-90deg);
`;
const ReadymadeTestCard = ({ template }) => {
       const getDifficultySettings = (difficultyLevel) => {
         switch (difficultyLevel) {
           case "easy":
             return {
               bgcolor: "rgba(67, 157, 98, 0.1)",
               color: "rgba(67, 157, 98, 1)",
               path: "/assets/greenfire.svg",
               difficulty: "Easy",
             };
           case "medium":
             return {
               bgcolor: "rgba(255, 151, 54, 0.1)",
               color: "rgba(255, 151, 54, 1)",
               path: "/assets/orangefire.svg",
               difficulty: "Medium",
             };
           case "hard":
             return {
               bgcolor: "rgba(249, 73, 72, 0.1)",
               color: "rgba(249, 73, 72, 1)",
               path: "/assets/libraryFire.svg",
               difficulty: "Hard",
             };
           default:
             return {
               bgcolor: "rgba(0, 0, 0, 0.1)",
               color: "rgba(0, 0, 0, 1)",
               path: "/assets/default.svg",
               difficulty: "Unknown",
             };
         }
       };
  return (
    <Grid item xs={12} sm={6} md={6} key={template.id}>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "16px",
          gap: "12px",
          backgroundColor: "#FFFFFF",
          border: "1px solid #E8E9EE",
          borderRadius: "4px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito",
            fontSize: "18px",
            fontWeight: "600",
            color: "#313131",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
            padding: "0px 0px 6px 0px",
            lineHeight: "24px",
          }}
        >
          {template.title}
          <Tooltip title="Test Preview" arrow>
            <img src="/assets/Visit.svg" alt="Test Preview" />
          </Tooltip>
        </Typography>

        <Stack direction={"row"} gap={"8px"} alignItems={"flex-start"}>
          {template.tag.map((tagName, index) => (
            <Typography
              sx={{
                padding: "4px 8px",
                height: "28px",
                background: "#F2F4F8",
                borderRadius: "4px",
                fontFamily: "Roboto",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#69778A",
              }}
            >
              {tagName}
            </Typography>
          ))}
        </Stack>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#535A5F",
          }}
        >
          {template.description}
        </Typography>
        <Stack
          divider={<Divider />}
          direction={"row"}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "8px 0px 8px 0px",
            boxSizing: "border-box",
            gap: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              padding: "4px 8px",
              backgroundColor: getDifficultySettings(
                template.difficultyLevel.level
              ).bgcolor,
              borderRadius: "4px",
            }}
          >
            <img
              src={getDifficultySettings(template.difficultyLevel.level).path}
              alt="difficulty"
            />
            <Typography
              sx={{
                color: getDifficultySettings(template.difficultyLevel.level)
                  .color,
                fontSize: "14px",
                fontWeight: 400,
                fontFamily: "Roboto",
                lineHeight: "20px",
              }}
            >
              {template.difficultyLevel.level.charAt(0).toUpperCase() +
                template.difficultyLevel.level.slice(1)}
            </Typography>
          </Box>
          <Stack direction={"row"} gap={"4px"}>
            <Iconify
              icon="tabler:clock"
              width={"20px"}
              color={"background.darkGray"}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Roboto",
                lineHeight: "20px",
                color: "#8591A2",
              }}
            >
              {template?.time}mins
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={"4px"}>
            <Iconify
              icon="octicon:question-24"
              width={"20px"}
              color={"background.darkGray"}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Roboto",
                lineHeight: "20px",
                color: "#8591A2",
              }}
            >
              {template?.questions} Ques.
            </Typography>
          </Stack>
          <Stack direction={"row"} gap={"4px"}>
            <Iconify
              icon="simple-line-icons:badge"
              width={"20px"}
              color={"background.darkGray"}
            />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                fontFamily: "Roboto",
                lineHeight: "20px",
                color: "#8591A2",
              }}
            >
              Score : {template?.score}
            </Typography>
          </Stack>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexDirection: "row",
            }}
          >
            <img
              src="/assets/icons/clipboardGray.svg"
              alt="remark"
              width={20}
              height={20}
            />

            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#71777B",
              }}
            >
              Cut-off : {template?.cutOff}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
};

export default ReadymadeTestCard
import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from "@mui/material";
const checkBox = ["All", "Candidate Missing", "Multiple Candidates"];
const CheatingCheckbox = () => {
  const theme = useTheme();
  return (
    <FormGroup row sx={{ gap: "8px" }}>
      {checkBox.map((elem, i) => (
        <FormControlLabel
          key={i}
          control={
            <Checkbox
              title="test"
              icon={
                <Box
                  width={"20px"}
                  height={"20px"}
                  sx={{
                    border: "1px solid",
                    borderRadius: "4px",
                    borderColor: theme.palette.secondary.main,
                  }}
                />
              }
              checkedIcon={
                <Box component={"img"} src={"/assets/icons/check.svg"} />
              }
              defaultChecked
              sx={{
                paddingY: "0",
                "&.Mui-checked": {
                  // color: theme.palette.secondary.main,
                },
              }}
            />
          }
          label={
            <Typography
              sx={{ fontSize: "14px", fontWeight: "400", color: "#363E45" }}
            >
              {elem}
            </Typography>
          }
        />
      ))}
    </FormGroup>
  );
};

export default CheatingCheckbox;

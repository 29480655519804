import React from "react";
import {
  Box,
  Drawer,
 
} from "@mui/material";
import { styled, } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },

  validityDropdown: {
    width: "100%",
    "& .MuiInputLabel-asterisk": {
      color: "#F94948",
    },
    "& .MuiInputBase-root": {
      height: "48px",
    },
  },
  mainHeading: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    fontFamily: "Nunito",
    color: theme.palette.primary.black,
  },
  footer: {
    display: "flex",
    gap: "24px",
    justifyContent: "flex-end",
    borderTop: "1px solid #E9EAEB",
    padding: "12px 20px",
    fontSize: "0.875rem",
    backgroundColor: theme.palette.background.default,
  },
  backDrop: {
    backdropFilter: "blur(2px)",
    backgroundColor: "rgba(49, 62, 79, 0.44)",
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("xs")]: {
    width: "360px",
  },
  [theme.breakpoints.up("xs")]: {
    width: "360px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "576px",
  },
  [theme.breakpoints.up("md")]: {
    width: "720px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "50vw",
  },
}));

function DrawerSlide({ toggleDrawer, setToggleDrawer, children }) {
  const classes = useStyles();
  

  return (
    <Drawer
      // classes={classes.drawer}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      anchor={"right"}
      open={toggleDrawer}
      onClose={() => setToggleDrawer((prev) => !prev)}
      ModalProps={{
        slotProps: {
          backdrop: {
            backgroundColor: "#313E4F",
            opacity: "70%",
          },
        },
      }}
    >
      <CustomBox className={classes.content}>{children}</CustomBox>
    </Drawer>
  );
}

export default DrawerSlide;

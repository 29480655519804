import axios from "axios";

export const serverURL = "https://staging.api.hire360.ai";
// export const serverURL = 'https://ec12-125-22-183-42.ngrok-free.app';
// export const serverURL = "https://discrete-pig-vaguely.ngrok-free.app";
// export const serverURL = "http://localhost:3026";

export const getAuthHeaders = () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "ngrok-skip-browser-warning": true,
  };
  return { headers };
};

// Create an object to store ongoing requests
let ongoingRequests = {};

const APICall = (url, data = {}, method = "get", authorization = true) => {
  return new Promise((resolve, reject) => {
    // Create a unique identifier for the request
    const requestIdentifier = `${url}_${method}_${JSON.stringify(
      data
    )}_${authorization}`;

    // If there's an ongoing request with the same identifier, cancel it
    if (ongoingRequests[requestIdentifier]) {
      ongoingRequests[requestIdentifier].cancel(
        "Operation canceled due to new request."
      );
    }

    // Create a new CancelToken source for the new request
    ongoingRequests[requestIdentifier] = axios.CancelToken.source();

    const finalURL = `${serverURL}${url}`;
    const headers = authorization ? getAuthHeaders() : {};
    let request;

    switch (method.toLowerCase()) {
      case "get":
        request = axios.get(finalURL, {
          cancelToken: ongoingRequests[requestIdentifier].token,
          ...headers,
        });
        break;
      case "post":
        request = axios.post(finalURL, data, {
          cancelToken: ongoingRequests[requestIdentifier].token,
          ...headers,
        });
        break;
      case "patch":
        request = axios.patch(finalURL, data, {
          cancelToken: ongoingRequests[requestIdentifier].token,
          ...headers,
        });
        break;
      case "delete":
        request = axios.delete(finalURL, {
          cancelToken: ongoingRequests[requestIdentifier].token,
          ...headers,
        });
        break;
      case "put":
        request = axios.put(finalURL, data, {
          cancelToken: ongoingRequests[requestIdentifier].token,
          ...headers,
        });
        break;
      default:
        reject(new Error(`Invalid method: ${method}`));
        return;
    }

    request
      .then((response) => {
        // Remove the request from ongoingRequests when it completes
        delete ongoingRequests[requestIdentifier];
        resolve(response);
      })
      .catch((error) => {
        // Remove the request from ongoingRequests when it fails
        delete ongoingRequests[requestIdentifier];
        if (!axios.isCancel(error)) {
          // If the error was due to something else, reject the promise
          reject(error);
        }
      });
  });
};

export default APICall;

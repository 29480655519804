import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import useResponsive from "../hooks/useResponsive";
import Header from "./Header";
import NavMini from "./NavMini";
import { Navbar } from "./Navbar";
import { Box } from "@mui/material";
import Main from "./Main";
import { navContext } from "../context/navContext";
import NavHorizontal from "./NavHorizontal";

const DashboardLayout = () => {
  const isDesktop = useResponsive("up", "md");
  const { isNavMini } = useContext(navContext);
  const [isVisible, setIsVisible] = useState(true);

   const handleClose = () => {
     setIsVisible(false);
   };
  const renderNavVertical = <Navbar isVisible={isVisible} onDismiss={handleClose}/>;

  if (isNavMini && isDesktop) {
    return (
      <>
        <Header title="Dashboard" />
        <Box
          sx={{
            display: { md: "flex" },
            minHeight: { md: 1 },
          }}
        >
          <NavMini />
          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }
  if (!isDesktop) {
    return (
      <>
        <Header title="Dashboard" />
        <Box
          sx={{
            display: { md: "flex" },
            minHeight: { md: 1 },
          }}
        >
          <NavHorizontal />
          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }
  return (
    <>
      <Header title="Dashboard" />
      <Box
        sx={{
          display: { md: "flex" },
          // minHeight: { md: 1 },
        }}
      >
        {renderNavVertical}
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
};

export default DashboardLayout;

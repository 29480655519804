import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import SearchBar from "../components/common/SearchBar";
import TabsList from "../components/candidateShortlisted/Tabs";
import AnalyticsHeader from "../components/assessmentAnalytics/AnalyticsHeader";
import ToggleSquareIcons from "../components/common/ToggleSquareIcons";
import Shortlisted from "../components/candidateShortlisted/Shortlisted";
import RoundOne from "../components/candidateShortlisted/RoundOne";
import RoundTwo from "../components/candidateShortlisted/RoundTwo";
import HRRound from "../components/candidateShortlisted/HRRound";
import Hired from "../components/candidateShortlisted/Hired";
import SearchTextField from "../components/searchTextField/SearchTextField";
import SortListedGrid from "../components/candidateShortlisted/SortListedGrid";
import RoundOneGrid from "../components/candidateShortlisted/RoundOneGrid";
import RoundTwoGrid from "../components/candidateShortlisted/RoundTwoGrid";
import HiredGrid from "../components/candidateShortlisted/HiredGrid";
import HRRoundGrid from "../components/candidateShortlisted/HRRoundGrid";
import AssesmentFilter from "../components/assesmentPage/AssesmentFilter";
import {
  candidate_shortlisted_tabs_two,
  shortlistedFilterData,
} from "../utils/data";
import { breadcrumbsContext } from "../context/breadcrumbsContext";
import { PATH_DASHBOARD } from "../routes/paths";
import Iconify from "../components/iconify/Iconify";

const ShortlistedCandidates = () => {
  const [shortCandidates, setShortCandidates] = useState(true);
  const [value, setTabValue] = useState(
    candidate_shortlisted_tabs_two[0].value
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);

  useEffect(() => {
    const currentTab = candidate_shortlisted_tabs_two.find(
      (tab) => tab.value === value
    );
    setBreadcrumbsData([
      { name: "Assessments", href: PATH_DASHBOARD.assessments },
      { name: currentTab ? currentTab.label : "" },
    ]);
  }, [value, setBreadcrumbsData]);

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  const renderContent = () => {
    if (shortCandidates) {
      switch (value) {
        case "shortlisted":
          return <SortListedGrid />;
        case "round1":
          return <RoundOneGrid />;
        case "round2":
          return <RoundTwoGrid />;
        case "hrround":
          return <HRRoundGrid />;
        case "hired":
          return <HiredGrid />;
        default:
          return null;
      }
    } else {
      switch (value) {
        case "shortlisted":
          return <Shortlisted />;
        case "round1":
          return <RoundOne />;
        case "round2":
          return <RoundTwo />;
        case "hrround":
          return <HRRound />;
        case "hired":
          return <Hired />;
        default:
          return null;
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        background: "#F2F4F8",
        display: "flex",
      }}
    >
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box sx={{ height: "100%", background: "#F2F4F8", width: "100%" }}>
          <Box sx={{ my: "10px" }}>
            <AnalyticsHeader />
          </Box>
          <Box
            sx={{
              width: "100%",
              margin: "16px 0",
              background: "#fff",
              padding: "1rem",
              border: "1px solid #E8E9EE",
              marginTop: "6px",
            }}
          >
            <Box
              sx={{
                display: { sm: "block", md: "flex" },
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "#010101",
                    fontFamily: "Nunito",
                  }}
                >
                  Shortlisted Candidates (250)
                </Typography>
                <Box sx={{ display: { base: "none", md: "block" } }}>
                  <Box sx={{ width: { base: "100%", md: "296px" } }}>
                    <SearchTextField placeholder="Search Candidate" />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { base: "column", sm: "row" },
                  alignItems: { base: "flex-end", sm: "center" },
                  gap: "16px",
                  mt: { base: "16px", md: "0px" },
                }}
              >
                <Box
                  sx={{
                    display: { base: "flex", md: "none" },
                    width: "100%",
                    gap: "16px",
                  }}
                >
                  <SearchBar
                    width="100%"
                    search={search}
                    setSearch={setSearch}
                  />
                </Box>

                <ToggleSquareIcons setShortCandidates={setShortCandidates} />
                <IconButton
                  sx={{
                    width: "36px",
                    height: "36px",
                    border: "1px solid",
                    borderRadius: "4px",
                    padding: 0,
                    borderColor: "secondary.main",
                  }}
                  onClick={handleFilterOpen}
                >
                  <Iconify
                    icon="mage:filter"
                    width={"16px"}
                    color={"secondary.main"}
                  />
                </IconButton>
              </Box>
            </Box>
            <TabsList
              handleTabChange={setTabValue}
              value={value}
              tabs={candidate_shortlisted_tabs_two}
            />
            <Box sx={{ mb: "16px" }}>{renderContent()}</Box>
          </Box>
        </Box>
      </Box>
      {isFilterOpen && (
        <AssesmentFilter
          open={isFilterOpen}
          onClose={handleFilterClose}
          filters={shortlistedFilterData}
        />
      )}
    </Box>
  );
};

export default ShortlistedCandidates;

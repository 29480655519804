import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import TabsList from '../candidateShortlisted/Tabs';
import { assessments_analytics_tab_list } from '../../utils/data';
import TestAnalytics from './TestAnalytics';
import QuestionsAnalytics from './QuestionsAnalytics';
import { breadcrumbsContext } from '../../context/breadcrumbsContext';


function Analytics() {

  const [value, setTabValue] = React.useState(
    assessments_analytics_tab_list[0].value
  );
  const { setBreadcrumbsData } = useContext(breadcrumbsContext);

  useEffect(() => {
    const currentTab = assessments_analytics_tab_list.find(
      (tab) => tab.value === value
    );

    setBreadcrumbsData((breadcrumbs) => [
      ...breadcrumbs.slice(0, 2), // Keep the first two levels of breadcrumbs
      { name: currentTab.label },
    ]);
  }, [value, setBreadcrumbsData]);

  return (
    <Box>
      <Typography sx={{ fontWeight: 700, fontSize: '18px', marginBottom: '16px' }}>Analytics</Typography>
      {/* tabs */}
      <TabsList
        tabs={assessments_analytics_tab_list}
        handleTabChange={setTabValue}
        value={value}
      />
      {value === 'test_analytics' &&
        <TestAnalytics />}
      {value === 'question_analytics' &&
        <QuestionsAnalytics />}
    </Box>
  );
}

export default Analytics
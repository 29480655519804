import React from 'react'
import CustomTable from '../../common/customTable/CustomTable';
import { Box, Typography } from '@mui/material';
import { question_analytics_table_rows } from '../../../utils/data';


const tableHeaderCellStylePropsQA = {
    color: "#363E45",
    lineHeight: "16px",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "Roboto",
    padding: "8px 16px",
    height: "36px",
    whiteSpace: "nowrap",
};
const tableContentCellTextStylePropsQA = {
    textAlign: "left",
    fontSize: "14px",
    color: "#363E45",
    fontWeight: "400",
    lineHeight: "20px",
    fontFamily: "Roboto",
};
const tableBodyRowStylePropsQA = {
    height: "48px",
};

const tableHeaderTitlesQA = [
    {
        title: "Sr. no",
        cellStyleProps: {
            borderRight: "1px solid #E8E9EE",
            width: "60px",
        },
    },
    {
        title: "Question",
        cellStyleProps: {
            borderRight: "1px solid #E8E9EE",
            width: "170px",
        },
    },
    {
        title: "Description",
        cellStyleProps: {
            width: "90px",
        },
    },
    {
        title: "Candidates Attempts",
        cellStyleProps: {
            width: "170px",
        },
    },
    {
        title: "Correct Attempts",
        cellStyleProps: {
            width: "140px",
        },
    },
    {
        title: "Incorrect Attempts",
        cellStyleProps: {
            width: "124px",
        },
    },
    {
        title: "Responses",
        cellStyleProps: {
            width: "80px",
        },
    },
];

const tableHeaderCellsQA = () => {
    return tableHeaderTitlesQA.map((item) => {
        return {
            element: item.title,
            cellStyleProps: item.cellStyleProps,
            isSortable: item?.isSortable ? item.isSortable : false,
            cellId: item?.cellId ? item.cellId : "",
        };
    });
};


export default function QuestionAnalyticsDataTable() {

    const tableBodyRowCellQA = (row, cellStyleProps) => {
        return [
            {
                cellStyleProps: {
                    borderRight: "1px solid #E8E9EE",
                    flex: "1",
                    ...cellStyleProps,
                },
                element: (
                    <Typography
                        sx={{
                            ...tableContentCellTextStylePropsQA,
                        }}
                    >
                        {row.rowId}
                    </Typography>
                ),
            },
            {
                cellStyleProps: {
                    whiteSpace: "nowrap",
                    width: "180px",
                    maxWidth: "180px",
                    ...cellStyleProps,
                },
                element: (
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            ...tableContentCellTextStylePropsQA,
                        }}
                    >
                        {row.question}
                    </Typography>
                ),
            },
            {
                cellStyleProps: {
                    width: "225px",
                    maxWidth: "225px",
                    whiteSpace: "nowrap",
                    ...cellStyleProps,
                },
                element: (
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            ...tableContentCellTextStylePropsQA,
                        }}
                    >
                        {row.discription}
                    </Typography>
                ),
            },
            {
                cellStyleProps: {
                    whiteSpace: "nowrap",
                    width: "130px",
                    maxWidth: "130px",
                    ...cellStyleProps,
                },
                element: (
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            ...tableContentCellTextStylePropsQA,
                        }}
                    >
                        {row.attempts}
                    </Typography>
                ),
            },
            {
                cellStyleProps: {
                    width: "130px",
                    maxWidth: "130px",
                    whiteSpace: "nowrap",
                    ...cellStyleProps,
                },
                element: (
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            ...tableContentCellTextStylePropsQA,
                        }}
                    >
                        {row.correct}
                    </Typography>
                ),
            },
            {
                cellStyleProps: {
                    whiteSpace: "nowrap",
                    width: "130px",
                    maxWidth: "130px",
                    ...cellStyleProps,
                },
                element: (
                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            ...tableContentCellTextStylePropsQA,
                        }}
                    >
                        {row.incorrect}
                    </Typography>
                ),
            },
            {
                cellStyleProps: {
                    whiteSpace: "nowrap",
                    width: "225px",
                    maxWidth: "225px",
                    ...cellStyleProps,
                },
                element: (
                    <Box sx={{ width: "225px", display: "flex", gap: "8px" }}>
                        {row.responses.map((elem, index) => (
                            <Box key={index} sx={{ width: "fit-content", padding: "2px 8px", borderRadius: "4px", backgroundColor: elem.answer === "correct" ? "#D5F9F1" : "#FADCDA", display: "flex", alignItems: "center" }}>
                                {elem.label}
                            </Box>
                        ))}
                    </Box>


                ),
            },
            // {
            //     cellStyleProps: {
            //         whiteSpace: "nowrap",
            //         width: "225px",
            //         maxWidth: "225px",
            //         ...cellStyleProps,
            //     },
            //     element: (
            //         <Typography
            //             sx={{
            //                 ...tableContentCellTextStylePropsQA,
            //                 overflow: "hidden",
            //                 textOverflow: "ellipsis",
            //             }}
            //         >
            //             {row.responses}
            //         </Typography>
            //     ),
            // },
        ];
    };

    const tableBodyContentQA = () => {
        const cellStyleProps = {
            borderBottom: "1px solid #E8E9EE",
            padding: "10px 14px",
        };

        return question_analytics_table_rows.map((row, idx) => {
            return {
                rowId: idx + 1,
                rowElement: tableBodyRowCellQA(
                    { ...row, rowId: idx + 1 },
                    cellStyleProps
                ),
            };
        });
    };
    return (
        <Box sx={{ background: "#fff", width: "100%", height: "100%" }}>
            <CustomTable
                tableHeaderCells={tableHeaderCellsQA()}
                tableBodyContent={tableBodyContentQA()}
                tableData={question_analytics_table_rows}
                sortRows={() => { }}
                tableBodyRowStyleProps={tableBodyRowStylePropsQA}
                tableHeaderCellStyleProps={tableHeaderCellStylePropsQA}
            />
        </Box>
    );
}


// @mui
import { Box } from "@mui/material";
import { NAV } from "../config-global";
import NavSectionMini from "./mini/NavSectionMini";
import navConfig from "../config-nav";
import { useNavigate } from "react-router-dom";
import NavToggleButton from "./NavToggleButton";
import theme from "../theme/theme";

export default function NavMini() {
  const navigate = useNavigate();

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        zIndex: 1001,
        overflowX: "auto",
        position: "relative",
        minWidth: NAV.W_DASHBOARD_MINI,
        background: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          pb: 2,
          px: "12px",
          height: 1,
          position: "fixed",
          overflowX: "auto",
        }}
      >
        <Box
          component="img"
          src="/assets/icons/LogoMini.svg"
          style={{
            width: "68px",
            height: "48px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
            marginBottom: "16px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
        <NavToggleButton />
        <NavSectionMini data={navConfig} />
      </Box>
    </Box>
  );
}
